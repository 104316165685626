import React, { useContext} from 'react';
import { useHistory } from 'react-router-dom';
import { AccountContext } from "../Authentication/Account.js";
import AuthHeader from "../Authentication/AuthHeader.js"

export default function AcceptData() {
  const history = useHistory();
  const { getSession } = useContext(AccountContext);

  const accept = () => {

    alert("You have accepted this data!");
    getSession().then(session => {
        fetch('/api/data/approve', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthHeader(session)
          },
          body: JSON.stringify({
            success: 'true',
            apiAction: 'start-process',
          })
        }).then(data => {
            history.push('/portfolio-performance');
        });
    });
  }

  return (
    <button
      onClick={accept}
      className="bg-yellow text-blue mb-4 text-16-19 px-12 py-4 rounded-lg"
      type="button"
    >
      ACCEPT DATA
    </button>
  );
}


