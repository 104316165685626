/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import WhiteNavbar from "../components/Navbars/WhiteNavbar.js";
import Footer from "../components/Footers/Footer.js";
import Terms from "../legal/TermsAndConditions.js";

import '../assets/styles/App.css';
import '../assets/styles/tailwind.css';
import "@fontsource/montserrat";


export default function Index() {
  window.scrollTo(0, 0);
  document.title = "VELA - Terms and Conditions";
  return (
    <>
      <section>
        <WhiteNavbar />
      </section>

      <section className="relative">
        <div className="container mx-auto mb-24 mt-12">
            <Terms />
        </div>
      </section>

      <Footer />
    </>
  );
}
