import React, { useState, useEffect } from "react";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";


export default function CardError(props) {
  const [message, setMessage] = useState('');

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);
  return (
    <>
            <div className="container relative mx-auto items-center bg-white-10 rounded-lg mt-12">
              <h3 className="text-24-500 text-white text-center py-16">{message}</h3>
              <br/>
            </div>
    </>
  );
}