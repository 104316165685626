/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import WhiteNavbar from "../components/Navbars/WhiteNavbar.js";
import Footer from "../components/Footers/Footer.js";

import '../assets/styles/App.css';
import '../assets/styles/tailwind.css';
import "@fontsource/montserrat";


export default function Index() {
  window.scrollTo(0, 0);
  document.title = "VELA - Privacy Policy";
  return (
    <>
      <section>
        <WhiteNavbar />
      </section>

      <section className="relative">
        <div className="container mx-auto mb-24 px-4">
          <h1 className="hl-48 md:text-center mt-12 mb-8">Privacy Policy</h1>

           <p className="text-16-150 mb-4">Last Updated: August 2021</p>
           <p className="text-16-150 mb-4">At JUMO we take your privacy very seriously and are committed to protecting the privacy and security of our customers and employees. This privacy notice outlines how your personal information is treated through the use of this website, our services and products, when you apply for a vacancy on our career site and when you communicate with us. If you would like to discuss this with us or have any questions, comments, complaints or suggestions, please <a class="ql-link" href="mailto:contact@jumo.world" target="_blank" rel="noopener noreferrer">contact us</a><span data-void-detect="true">.</span><br/>
              This privacy notice should be read together with our website Terms of Use and Cookie Policy
           </p>

           <h2 className="text-24-500 mt-8 mb-4">1. Who we are</h2>
           <p className="text-16-150 mb-4">“JUMO”, “we”, “us” or “our” refers to JUMO World Limited (registration number 095315) and all its affiliated companies, subsidiaries, departments, partners, service providers and contractors. “Websites” refers to all websites owned and operated by JUMO. For the purposes of applicable data protection laws in the territories that we operate, JUMO acts as a processor of customer information and a controller of employee information</p>

           <h2 className="text-24-500 mt-8 mb-4">2.&nbsp; Our Privacy Principles</h2>
           <p className="text-16-150 mb-4">JUMO is a global organisation and for this reason we have adopted privacy principles that govern our operations and are aligned to data protection regulations across all territories we operate in.</p>
           <ul className="circle">
              <li>We will only collect, transfer, process and store your personal information with your consent and for the purpose it is required, unless legally required to do so.</li>
              <li>We will only use your personal information for the original purpose it is required. We are open about how we use this information during our interactions with you. Should this purpose change, we will immediately notify you.</li>
              <li>We will only collect, transfer, process and store the personal information that is required and no more.</li>
              <li>We aim to ensure that your personal information is kept up to date at all times.</li>
              <li>We will only store your personal information for as long as required.</li>
              <li>We will protect your personal information at all times.</li>
              <li>We will ensure we implement all legal requirements and principles to protect your personal information and we acknowledge that you have certain rights as a data subject to query the information we hold.</li>
           </ul>
           <h2 className="text-24-500 mt-8 mb-4">3. The information we collect</h2>
           <p className="text-16-150 mb-4">We may collect, use, store and transfer different kinds of personal information about you through the use of this website, our services and products, applying for a job vacancy or communicating with us&nbsp; which we have grouped together as follows:</p>
           <ul className="circle">
              <li>Identity Data may include first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender, identity or passport number or any other form of official or nationally recognised identity.</li>
              <li>Contact Data includes home and postal address, email address, telephone numbers and country of residence.</li>
              <li>Financial Data includes mobile money account and payment details.</li>
              <li>Transaction Data includes details about payments to and from you and other details of products and services you have purchased from us or our partners.</li>
              <li>Technical Data includes internet protocol (IP) address, your login information (where applicable), browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this Site.</li>
              <li>Usage Data includes information about how you use our website, products and services.</li>
              <li>Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
              <li>Correspondence Data includes any correspondence and communications with JUMO</li>
              <li>Recruitment and Employment Data including previous job history, educational details and contact details</li>
              <li>Other publicly available personal data, including any which you have shared via a public platform (such as Twitter, Facebook or LinkedIn)</li>
           </ul>
           <p className="text-16-150 mb-4">Our websites, products and services are not intended for children and we do not knowingly collect the personal information related to children.</p>

           <h2 className="text-24-500 mt-8 mb-4">4. What about sensitive personal information?</h2>
           <p className="text-16-150 mb-4">As a customer of JUMO, who makes use of our products and services, we do not seek to collect any sensitive personal information (e.g race, colour, ethic origin, political opinion, physical and mental health, religious, sexual orientation, genetic data or criminal records). We ask that you do not provide such information to us.</p>
           <p className="text-16-150 mb-4">As a JUMO employee or potential JUMO employee, our Human Resources programmes require personal information about you to function properly. In limited circumstances, some programmes may involve certain sensitive health information (e.g. medical certificates submitted to us or other health-related benefits processes), financial information (e.g. payroll) or information about race or religion when mandated by local laws. Such sensitive personal information will be treated with the utmost care and in accordance with special requirements set out in applicable data protection and other laws in the territories in which we recruit.</p>

           <h2 className="text-24-500 mt-8 mb-4">5. How is your personal information collected?</h2>
           <p className="text-16-150 mb-4">We use different methods to collect information from and about you:</p>
           <p className="text-16-150 mb-4">1. Direct interactions. You may give us your personal information when registering on or submitting information through any JUMO website or by corresponding with us by post, phone, email or otherwise. This includes Personal Data you provide when you:</p>
           <ul className="circle">
              <li>create an account on any of our platforms</li>
              <li>subscribe to our service or publications;</li>
              <li>as part of our contractual agreement through accepting our product terms and conditions;</li>
              <li>request marketing to be sent to you;</li>
              <li>apply for an employment vacancy</li>
              <li>enter a competition, promotion or survey</li>
              <li>give us feedback or contact us directly ; or</li>
              <li>provide feedback or contact any of our partners</li>
           </ul>
           <p className="text-16-150 mb-4">2. Automated technologies or interactions. As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies.<br/>
              We also collect, use and share Aggregated Data such as statistical or demographic information for any purpose. Aggregated Data could be derived from your personal information but it will not directly or indirectly reveal your identity.
           </p>

           <h2 className="text-24-500 mt-8 mb-4">6. Cookies and Similar technologies</h2>
           <p className="text-16-150 mb-4"><strong>Cookies</strong><br/>
              We employ the use of cookies, which are small text files which asks your web browser for permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic. Cookies allow us to tailor our operations and websites to you, e.g. by estimating our audience size and usage patterns, store information about your preferences and recognise when you return to our website.<br/>
              Overall, cookies help us to provide you with a better website, by enabling us to monitor which pages you find useful or not. A cookie in no way gives us access to your computer or any of your personal information, other than that which you choose to share with us. We own and retain all rights to de-identified statistical information collected and compiled by us.<br/>
              You may at any time decline cookies in your web browser settings, but this may prevent you from taking full advantage of our websites. Refer to our cookie policy for more detailed information regarding <a class="ql-link" href="https://jumo.world/cookie-policy/" target="_blank" rel="noopener noreferrer">cookies</a><span data-void-detect="true">.</span>
           </p>
           <p className="text-16-150 mb-4"><strong>Third Party Analytics</strong><br/>
              In order to ensure that the content of our websites remain up to date, user-friendly and comprehensive, we use a web-analysis tool from <a class="ql-link" href="https://www.google.com/" target="_blank" rel="noopener noreferrer">Google</a>&nbsp;, i.e. Google Analytics. This service uses cookies that track traffic on our website and your preferences during your visits to our websites. The information that is used during this process (which includes your IP Address) is de-identified and makes personal identification impossible.<br/>
              For more information on the use of Google Analytics and your privacy options please visit <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener">https://policies.google.com/technologies/partner-sites</a> and for detail regarding the information Google collects and how it is used to deliver targeted advertising, please visit <a href="https://www.google.com/policies/privacy/ads" target="_blank" rel="noopener">https://www.google.com/policies/privacy/ads</a>.
           </p>

           <h2 className="text-24-500 mt-8 mb-4">7. How we use your data</h2>
           <p className="text-16-150 mb-4">We may use your information for purposes of operating our business and other legitimate purposes permitted by applicable law.&nbsp; We may use your personal information in the following ways:</p>
           <ul className="circle">
              <li>To provide our products and services to you</li>
              <li>To verify your identity</li>
              <li>To administer the careers portion of the website and process your application.</li>
              <li>To better understand how visitors use our website and products and services, both on an aggregated and individualized basis, in order to improve our website and products and services and for other research and analytical purposes.</li>
              <li>To send communications to you, either from JUMO directly or from our partners, where you have provided consent in order to do so.</li>
              <li>For customer insight and market research purposes, to better understand your needs</li>
              <li>To comply with applicable laws</li>
              <li>For crime and fraud prevention, detection and related purposed</li>
              <li>To enable our employees to manage customer service interactions with you</li>
              <li>To enforce our Terms of Use</li>
              <li>Where we have a legal right or duty to use or disclose your information (for example in relation to an investigation by a public authority or in a legal dispute)</li>
           </ul>

           <h2 className="text-24-500 mt-8 mb-4">8. Transfers of personal information</h2>
           <p className="text-16-150 mb-4">Because JUMO is a global organisation, your personal information may be transferred to and stored outside the country where you are located in order to provide our products and services to you. When we transfer your personal information, whether to cloud hosting facilities or within the JUMO Group, we will always protect that information as described in this privacy policy.</p>

           <h2 className="text-24-500 mt-8 mb-4">9. Third party providers</h2>
           <p className="text-16-150 mb-4">We may share your personal information with third parties in order to deliver our products and related services. We require all third parties, through our contractual agreements with them, to respect the security and confidentiality of your personal information and to treat it in accordance with the required data protection laws. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data in accordance with our instructions.</p>

           <h2 className="text-24-500 mt-8 mb-4">10. Your rights as a data subject</h2>
           <p className="text-16-150 mb-4">You may have certain rights in relation to personal information under the data protection laws in the&nbsp;<a class="ql-link" href="https://jumo.world/contact/" rel="noopener noreferrer">territories </a> we may operate or recruit.<br/>
              Right to access (Territories: Ghana, Kenya, Uganda, Pakistan, South Africa, EU, Côte D’Ivoire) – To request confirmation of whether we process personal data relating to you, and if so, to request a copy of that personal data;<br/>
              Right to Erasure, Deletion or Destruction (Territories: Ghana, Kenya, Uganda, Pakistan, South Africa, EU, Côte D’Ivoire) – to request that we delete your personal data in certain circumstances, where we no longer have any legal reason to retain it.<br/>
              Right to Rectification (Territories: Ghana, Kenya, Uganda, Pakistan, South Africa, EU, Côte D’Ivoire)&nbsp; – To request that we update or correct any personal data that is inaccurate, incomplete or outdated;<br/>
              Right to Object or Withdraw Consent (Territories: Ghana, Kenya, Uganda, Pakistan, South Africa, EU)&nbsp; &nbsp; – To request that you are opted out of any marketing communication that we may send you and to object to us using or holding your personal data if we have no legitimate reason to do so.<br/>
              Right to Restriction of Processing (Territories: Ghana, Kenya, Uganda, Pakistan,&nbsp; South Africa, EU, Côte D’Ivoire) – to request that we restrict the use of your personal data in certain circumstances, which means that we can secure and retain the data for your benefit but not use it.<br/>
              Right to be informed (Territories: Kenya, Pakistan, Uganda, Côte D’Ivoire) – The right to be informed of whether we have collected your personal data or use it for automated decision-making.<br/>
              Right to Data Portability (Territories: Kenya, EU)&nbsp; – to request that we provide a copy of your personal data to you in a structured, commonly used and machine readable format in certain circumstances.<br/>
              You can exercise any of these rights in accordance with the data protection regulation in the territories we operate by email or letter. Each request will be reviewed through our internal JUMO data request process and we endeavour to provide you with feedback within 20 working days. All requests received will be recorded. Please include the following information in your letter, to help us address the request:
           </p>
           <ul className="circle">
              <li>Requester name, contact information and company registration or KYC documentation</li>
              <li>
                 Details of the customer or employee whose data is requested (if not your own):
                 <ul className="circle">
                    <li>Name, Surname</li>
                    <li>Country of residence</li>
                    <li>Mobile number, or employee number, pertaining to the data</li>
                 </ul>
              </li>
              <li>Motivation and authorisation documentation supporting the request for the deletion of, or access to, the data</li>
              <li>Email details of where the data can be sent to if the request for data is approved</li>
           </ul>
           <p className="text-16-150 mb-4">Email:</p>
           <ul className="circle">
              <li>For Customer Data Requests please send an email to <a class="ql-link" href="mailto:customerdata_requests@jumo.world" target="_blank" rel="noopener noreferrer">customerdata_requests@jumo.world</a></li>
              <li>For Employee Data Requests please send an email to <a class="ql-link" href="mailto:employeedata_requests@jumo.world" target="_blank" rel="noopener noreferrer">employeedata_requests@jumo.world</a></li>
           </ul>
           <p className="text-16-150 mb-4">Letter:<br/>
              A formal letter can be sent to any of our country offices listed on the website, <a href="https://jumo.world/contact/">jumo.world/contact</a>
           </p>

           <h2 className="text-24-500 mt-8 mb-4">11. Complaints and grievances</h2>
           <p className="text-16-150 mb-4">You can submit a grievance about our services and the processing of your personal data in relation to this Privacy Notice by email or letter. (Where it is not possible for you to do so our in-office staff may assist you in documenting your case.)<br/>
              All grievances received will be recorded. Please include the following in your letter, to help us resolve the issue:
           </p>
           <ul className="circle">
              <li>Your name and contact information. (Where needed we may use the information you submit to us to contact you directly with regard to the grievance raised.)</li>
              <li>The mobile number you used to access the product or service that resulted in this complaint. (We will use this to investigate your account.)</li>
              <li>The date of the incident.</li>
              <li>Any documentation supporting your grievance.</li>
           </ul>
           <p className="text-16-150 mb-4">Email: <a href="mailto:customer_grievance@jumo.world" target="_blank" rel="noopener noreferrer">customer_grievance@jumo.world</a><br/>
              Letter: The addresses of each of our country offices are listed on our website, <a href="https://jumo.world/contact/">jumo.world/contact</a>
           </p>

           <h2 className="text-24-500 mt-8 mb-4">12. Retention</h2>
           <p className="text-16-150 mb-4">JUMO only retains your personal data for as long as needed to fulfil the purposes for which it is collected, unless we are required or permitted by law to keep the personal data for longer in accordance with our JUMO Retention Policy.<br/></p>

           <h2 className="text-24-500 mt-8 mb-4">13. Security and Data Breaches</h2>
           <p className="text-16-150 mb-4">JUMO is committed to keeping your personal data safe and secure. For this reason we have put in place appropriate administrative, technical and physical security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to the same confidentiality and security requirements that we follow.</p>
           <p className="text-16-150 mb-4">We have also put in place procedures to deal with any suspected personal data breach and will notify you, if you have been affected, as well as any applicable regulator of a breach where we are legally required to do so.</p>

           <h2 className="text-24-500 mt-8 mb-4">14. Updates to this policy</h2>
           <p className="text-16-150 mb-4">We may update this policy from time to time and will indicate this change by revising the date at the top of this policy. Please check this privacy policy regularly to stay informed about our information practices.</p>
        </div>
      </section>

      <Footer />
    </>
  );
}






