import { CognitoUserPool } from 'amazon-cognito-identity-js';

var pool_id = process.env.REACT_APP_USER_POOL_ID;
var client_id = process.env.REACT_APP_USER_POOL_CLIENT_ID;

const poolData = {
    UserPoolId: pool_id,
    ClientId: client_id
}

export default new CognitoUserPool(poolData);