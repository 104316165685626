import React, { useState, useEffect } from "react";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";

import MarginTargetChart from "../Charts/PerformanceMarginTarget.js";


export default function CardMarginTarget(props) {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (Object.keys(props.chartData).length > 0) {
        setChartData(props.chartData)
    }
  }, [props.chartData]);
  return (
    <>
        <div className="relative container mx-auto mb-12 mt-8">

            <div className="relative w-full px-4 mb-8">
              <div className="relative bg-white-7 w-full rounded-lg p-4 mb-8">
                <MarginTargetChart
                    target={chartData.performance_target_baseline}
                    performance={chartData.performance_rolling_30day_performance_target}
                    loanVolume={chartData.performance_rolling_30day_loan_volume}
                    labels={chartData.performance_dates}
                    title='Facility Performance Margin to Target'
                />
              </div>
            </div>

        </div>
    </>
  );
}
