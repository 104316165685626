import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";

import { ProtectedRoute } from "./ProtectedRoute.js";
import { AdminRoute } from "./AdminRoute.js";

import HealthCheck from "./views/HealthCheck.js";
import Index from "./views/Index.js";

import PopUp  from "./components/Modals/PopUpModal.js";
import CookiePopUp  from "./components/Modals/CookieModal.js";

import { Account } from "./components/Authentication/Account";
import Status from "./components/Authentication/Status";
import Settings from "./components/Authentication/Settings";
import Login from "./views/Login.js";
import Register from "./views/Register.js";
import MarketResearch from "./views/MarketResearch.js";
import Error404 from "./views/Error404.js";

import PerformanceDashboard from "./views/PerformanceDashboard.js";
import FundDashboard from "./views/FundDashboard.js";
import FundDashboardAdmin from "./views/FundDashboardAdmin.js";

import TermsAndConditions from "./views/TermsAndConditions.js";
import PrivacyPolicy from "./views/PrivacyPolicy.js";
import CookiePolicy from "./views/CookiePolicy.js";


const App = () => {
    return (
        <Account>
            <PopUp />
            <CookiePopUp />
            <Status />
            <Switch>
                <Route path="/" exact component={Index} />

                <Route path="/settings" exact component={Settings} />
                <Route path="/register" exact component={Register} />
                <Route path="/research-question" exact component={MarketResearch} />
                <Route path="/login" exact component={Login} />

                <ProtectedRoute path="/portfolio-performance" exact component={PerformanceDashboard} />
                <ProtectedRoute path="/fund-performance/:facility_code" exact component={FundDashboard} />
                <AdminRoute path="/fund-performance-admin" exact component={FundDashboardAdmin} />

                <Route path="/terms" exact component={TermsAndConditions} />
                <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                <Route path="/cookie-policy" exact component={CookiePolicy} />

                <Route path="/health_check" exact component={HealthCheck} />

                <Route path="*" exact component={Error404} />
            </Switch>
        </Account>
    );
}

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
    hydrate(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
        rootElement
    );
} else {
    render(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
        rootElement
    );
}

