import React, { useState, useEffect, useRef, useContext} from "react";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";

import { ReactComponent as Venn } from '../../assets/img/Venn Illus.svg';
import { AccountContext } from "../Authentication/Account.js";
import AuthHeader from "../Authentication/AuthHeader.js"

import { numberWithCommas } from '../../functions/NumberFunctions.js';


export default function CardInactiveFundDetails(props) {
  const [fund, setFund] = useState([]);
  const { getSession } = useContext(AccountContext);

  useEffect(() => {
    if (props.fund.fund_code) {
      setFund(props.fund);
    }
  }, [props.fund]);

  const openPDFReport = fund_code => {
    getSession().then(session => {
        fetch('/api/download/' + fund_code,
                {
                    method: "GET",
                    headers: {"Content-Type": "application/json", "Authorization": AuthHeader(session)}
                }).then(res => res.json())
                  .then(data => {
                    if (data.url) {
                        window.open(data.url, '_blank', 'noopener,noreferrer');
                    }
        });
    });

  };

  return (
    <>
      <div className="w-full lg:w-4/12 px-4 py-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-b-lg rounded-t-xl">
            <div className="bg-blue-4 rounded-t-lg">
              <div className="flex items-center py-6">
                <div className="w-4/12 px-6">
                  <Venn className="center h-95-px w-full"/>
                  <p className="pt-2 text-24-performance text-white text-center">{(Math.round(fund.fund_interest * 100) / 100).toFixed(2)}%</p>
                </div>
                <div className="w-8/12 pr-4">
                  <h1 className="text-27-600 text-yellow">{fund.fund_name}</h1>
                  <p className="pt-1 text-16-performance text-yellow">{fund.fund_description_short}</p>
                  <p className="text-16-19 text-white pt-8 bottom-0">Annualised Return</p>
                </div>
              </div>
            </div>

            <p className="px-6 pt-6 pb-4 text-16-performance text-blue text-left">
              Principal <span>${numberWithCommas(Math.round(fund.fund_principal))}</span>
            </p>
            <p className="px-6 pb-4 text-16-performance text-blue">
              Duration <span>{fund.fund_duration} months</span>
            </p>
            <p className="px-6 pb-4 text-16-performance text-blue">
              Maturity <span>{fund.fund_maturity_date}</span>
            </p>
            <p className="px-6 pb-4 text-16-performance text-blue">
              Currency <span>{fund.fund_currency}</span>
            </p>
            <button
              className="bg-yellow center text-blue mb-4 text-16-19-bold px-12 py-3 rounded"
              onClick={() => openPDFReport(fund.fund_code)}
              type="button"
            >
              Fact sheet
            </button>
          </div>
        </div>
    </>
  );
}
