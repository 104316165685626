/*eslint-disable*/
import React, { useState, useEffect }  from "react";
import { Link } from "react-router-dom";

import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import Footer from "../components/Footers/Footer.js";
import HomeCarousel from "../components/Carousel/HomeCarousel.js";

import '../assets/styles/App.css';
import '../assets/styles/tailwind.css';
import "@fontsource/montserrat";

import { ReactComponent as AssetEngine } from '../assets/img/IMG_asset_management.svg';
import { ReactComponent as MultiCurrency } from '../assets/img/IMG_multicurrency.svg';
import { ReactComponent as VerifiedESG } from '../assets/img/IMG_verified_esg.svg';

import { ReactComponent as Venn } from '../assets/img/Venn Illus.svg';
import { ReactComponent as BTCVenn } from '../assets/img/Group 71.svg';

import { ReactComponent as CircleOne } from '../assets/img/Rectangle 120.svg';
import { ReactComponent as CircleTwo } from '../assets/img/Rectangle 121.svg';
import { ReactComponent as CircleThree } from '../assets/img/Rectangle 122.svg';

import { ReactComponent as HeaderGradient } from '../assets/img/gradient_img_bottom_2.svg';
import { ReactComponent as HeaderGradientMob } from '../assets/img/Header_greadient_mob.svg';
import { ReactComponent as NavbarGradient } from '../assets/img/Rectangle 44.svg';

import { ReactComponent as LargeLogo } from '../assets/img/logo_large_new.svg';
import { ReactComponent as LargeLogoBlue } from '../assets/img/Vela_large_whiteBG.svg';
import { ReactComponent as LogoJumo } from '../assets/img/JUMO_Logo.svg';
import { ReactComponent as LargeGraph } from '../assets/img/LargeGraph.svg';
import { ReactComponent as MobileGraph } from '../assets/img/graph_mobi.svg';

import PeopleImage from '../assets/img/People 1.png';
import ModalImage from '../assets/img/Video.png';
import HeaderImage from '../assets/img/Header_mob_3x.png';
import { ReactComponent as VideoPlay } from '../assets/img/IMG_Play_BTN.svg';


export default function Index(props) {
  const [modalIsOpen, setIsOpen] = useState(false);

  const [headerVideo, setHeaderVideo] = useState();
  const [secondVideo, setSecondVideo] = useState();
  const [modalVideo, setModalVideo] = useState();

  const [header, setHeader] = useState(false);
  const [second, setSecond] = useState(false);


  const onOpen = (event) => {
        event.preventDefault();
        setIsOpen(true);
    };

  const onClose = (event) => {
        event.preventDefault();
        setIsOpen(false);
    };

  const screenSize = (window.innerWidth >= 912);
  const screenSizeTwo = (window.innerWidth >= 1024);

  document.title = "VELA - Intelligent fixed income investing";

  useEffect(() => {
    fetch('/api/video/Header')
      .then(res => res.json())
      .then(data => {
        if (data.url) {
            setHeaderVideo(data.url);
            setHeader(true);
        }
    });
  }, []);

  useEffect(() => {
    fetch('/api/video/Second')
      .then(res => res.json())
      .then(data => {
        if (data.url) {
            setSecondVideo(data.url);
            setSecond(true);
        }
    });
  }, []);

  useEffect(() => {
    fetch('/api/video/Modal')
      .then(res => res.json())
      .then(data => {
        if (data.url) {
            setModalVideo(data.url);
        }
    });
  }, []);

  return (
    <>
      <section>
        <IndexNavbar />
      </section>

      <section className="header relative top-0 md:pt-16 flex h-screen bg-blue">
        <div className="top-0 w-full z-40 absolute">
            <NavbarGradient />
        </div>

        { header ? (
            <video width="w-100" className="top-0 z-10 absolute w-100" autoPlay loop muted playsInline>
                <source
                    src={headerVideo}
                    type="video/mp4"
                />
                Your browser does not support HTML5 video.
            </video>
            ) : (
            <></>
            )
        }

        { screenSize ? (
            <div className="bottom-0 w-full h-screen z-40 absolute grad-header">
                <></>
            </div>
            ) : (
            <div className="top-0 w-full h-20-pc z-40 absolute grad-header">
                <></>
            </div>
            )
        }

        <div className="container relative z-40 mx-auto flex">
            <div className="text-center px-8 bottom-0 absolute">
              <h2 className="hl-72 text-white mt-48 px-2">
                Intelligent fixed income investing with impact
              </h2>
              <div className={`text-center max-w-1000-px mx-auto ${screenSize && 'mt-6'}`}>
                  <p className="pt-8 md:mt-16 text-white text-24-150">
                      VELA has delivered attractive returns to investors while giving
                      entrepreneurs the capital they need to grow.  Sign up to follow our performance.
                  </p>
              </div>
              <div className={`pt-12 ${screenSize && 'mt-4'}`}>
                <Link
                  className="get-started text-blue text-16-19-bold px-8 py-3 rounded mb-1 bg-yellow "
                  to="/register"
                >
                  Join our journey
                </Link>
              </div>
              <div className={`py-10 ${screenSize && 'mt-3'}`}>
                <Link
                  className="get-started text-yellow text-16-19 px-6 py-4 rounded mb-1"
                  to="/login"
                >
                  Sign In
                </Link>
              </div>
            </div>
        </div>
      </section>

      <section className="z-10 relative bg-blue">
        <div className="container mx-auto">
          <div className="flex items-center text-center">
            <div className={`px-6 ${screenSize && 'py-16'}`}>
                <p className="text-yellow text-36 mt-16 mb-12">Investment in private credit portfolios alongside major banks</p>
                <p className="text-white text-20-30 mb-24">
                    VELA makes it possible for qualifying investors to put money to work alongside
                    pan-African banks and watch it perform in real time. VELA is an investment
                    management platform from JUMO, the leading financial services
                    infrastructure provider for lending and savings in Africa.
                </p>
            </div>
          </div>
        </div>
      </section>

      <section className="relative bg-white">
        <div className="container mx-auto py-10">
          <div className={`flex flex-wrap items-center px-8 ${screenSize && 'py-16'}`}>
            <div className="w-full md:w-5/12 lg:w-5/12 md:px-4 py-10 flex justify-center">
                <LargeLogoBlue />
            </div>

            <div className="w-full lg:w-7/12 md:w-7/12 ">
              <div className="flex flex-wrap items-center">
                <div className="w-full md:w-10/12 lg:px-12 md:px-12">
                  <p className="text-36-130 text-blue-v-dark py-4 text-center md:text-left">
                    VELA's portfolio of loans, backed by JUMO's predictive technology,
                    delivers consistent performance.
                  </p>
                  <p className="text-20-30 text-blue-v-dark py-4 text-center md:text-left">
                    VELA investments have backed over 11.5 million loans to entrepreneurs
                    to help grow their businesses. These diverse portfolios provide
                    returns that are uncorrelated with public capital markets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`pb-20 relative bg-blue ${screenSizeTwo && 'h-912-px'}`}>
        <div className="container mx-auto pt-20">
          <div className="justify-center flex flex-wrap items-center">
            <h3 className={`mb-2 hl-48 text-white mt-8 ${screenSize && 'py-2'}`}>
              How it works
            </h3>
            <div className="w-full lg:w-12/12 mt-12">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className={`relative flex flex-col min-w-0 break-words text-white bg-white-7 w-full mb-6 rounded-lg py-10 px-8 ${screenSize && 'h-600-px'}`}>
                    <div className="mb-6">
                      { screenSize ? (
                            <AssetEngine className="size-how-it-works-large center my-4"/>
                        ) : (
                            <AssetEngine className="size-how-it-works"/>
                        )
                      }

                    </div>
                    <h1 className="pb-6 text-24-500">Sharing value</h1>
                    <p className="text-16-150">
                        VELA is powered by JUMO’s capital management system which has allowed qualified
                        investors to invest alongside banks at the same time as funding borrowers.
                        Contractual returns have been met in all investment facilities since inception.
                    </p>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className={`relative flex flex-col min-w-0 break-words text-white bg-white-7 w-full mb-6 rounded-lg py-10 px-8 ${screenSize && 'h-600-px'}`}>
                    <div className="mb-6">
                      { screenSize ? (
                            <MultiCurrency className="size-how-it-works-large center my-4"/>
                        ) : (
                            <MultiCurrency className="size-how-it-works"/>
                        )
                      }
                    </div>
                    <h1 className="pb-6 text-24-500">Multi currency + crypto</h1>
                    <p className="text-16-150">
                        Our multi currency capital interface manages capital in USD, GBP, EUR and
                        BTC. A proprietary capital optimization algorithm enables thousands of
                        individual funding sources and strategies. Risk is diversified across 11.5
                        million individual loans per year, in 6 markets, across 13 products.
                    </p>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className={`relative flex flex-col min-w-0 break-words text-white bg-white-7 w-full mb-6 rounded-lg py-10 px-8 ${screenSize && 'h-600-px'}`}>
                    <div className="mb-6">
                      { screenSize ? (
                            <VerifiedESG className="size-how-it-works-large center my-4"/>
                        ) : (
                            <VerifiedESG className="size-how-it-works"/>
                        )
                      }
                    </div>
                    <h1 className="pb-6 text-24-500">Unmatched, verified ESG</h1>
                    <p className="text-16-150">
                        We optimize for maximum customer health and inclusion. This means growing
                        access to finance for the world’s unbanked population and  monitoring
                        mandates that incorporate social impact goals. We provide transparent
                        <a href="https://jumo.world/environmental-social-and-governance/" target="_blank" className="text-yellow"> ESG reporting </a>
                        and are <a href="https://www.bcorporation.net/en-us/find-a-b-corp?query=jumo" target="_blank" className="text-yellow"> B-Corp certified</a>.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="grad-1 py-16 bg-blue px-4">
        <div className={`container mx-auto rounded-lg py-12 ${screenSize && 'mb-12'}`}>
          <div className="justify-center flex flex-wrap grad-4">
            <h3 className={`text-center hl-48 text-white ${screenSize && 'py-6'}`}>
              Proven Past Performance
            </h3>
            <div className={`w-full lg:w-12/12 px-4 mt-6 ${screenSize && 'py-6'}`}>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words text-white text-center w-full mb-6">
                    <p className="px-6 py-6 text-90">
                        <CountUp
                            start={1}
                            end={8}
                            duration={1.5}
                            useEasing={true}
                        >
                            {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}
                        </CountUp>
                    </p>
                    <p className="px-8 pb-6 text-20-29">Closed facilities</p>
                  </div>
                </div>

                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words text-white text-center w-full mb-6">
                    <h1 className="px-2 py-6 text-90">
                        <span className="percentage-span">$</span>
                        <CountUp
                            start={1}
                            end={626}
                            duration={1.5}
                            useEasing={true}
                        >
                            {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}
                        </CountUp>
                        <span className="percentage-span">m</span>
                    </h1>
                    <p className="px-12 pb-6 text-20-29">Total loan disbursements</p>
                  </div>
                </div>

                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words text-white text-center w-full mb-6">
                    <h1 className="px-6 py-6 text-90">
                        <CountUp
                            start={0.1}
                            end={3.1}
                            duration={1.5}
                            decimals={1}
                            decimal="."
                            useEasing={true}
                        >
                            {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}
                        </CountUp>
                        <span className="percentage-span">m</span>
                    </h1>
                    <p className="px-8 text-20-29">Customers served</p>
                  </div>
                </div>

                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words text-white text-center w-full mb-6">
                    <h1 className="px-2 py-6 text-90">
                        <CountUp
                            start={0.1}
                            end={11.5}
                            duration={1.5}
                            decimals={1}
                            decimal="."
                            useEasing={true}
                        >
                            {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}
                        </CountUp>
                        <span className="percentage-span">m</span>
                    </h1>
                    <p className="px-12 pb-6 text-20-29">Loans issued</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full">
                <div className="text-center mt-12 mb-8">
                  <p className="text-yellow text-20-30 mb-4">
                      Each $100,000 invested in VELA since inception yielded an annualized fixed return of
                  </p>
                  <p className="text-yellow text-96">14.44<span className="billion-span">%</span></p>
                </div>

                <div className="">
                  { screenSize ? (
                    <LargeGraph className="-mb-20"/>
                    ) : (
                    <MobileGraph className="-mb-10"/>
                    )
                  }
                </div>
            </div>
          </div>

          <div className="bg-blue-grey py-10 px-6 rounded-b-lg">
              <div className="flex">
                <CircleOne className="mr-4"/>
                <p className="text-blue text-16-19 mb-2">VELA Fixed Income Investment</p>
              </div>

              <div className="flex">
                <CircleThree className="mr-4"/>
                <p className="text-blue text-16-19 mb-2">JP Morgan EM Variable Debt Fund</p>
              </div>

              <div className="flex">
                <CircleTwo className="mr-4"/>
                <p className="text-blue text-16-19 mb-12">Vanguard EM Variable Debt Fund</p>
              </div>

              <p className="text-10-400 text-black-grey">
                  All data displayed relates to portfolios that are closed to new funding. Under
                  no circumstances should any information be used or considered as an offer to
                  sell, or the invitation or solicitation of an offer to buy or invest in, any
                  facility listed on this website or the giving of investment advice. You may not
                  rely on the financial assumptions or estimates that are displayed. Not all
                  risks are disclosed and risks will vary depending on your circumstances. Past
                  performance does not indicate future results. Forward-looking statements may
                  be published; however, actual results may materially vary. Please see full
                  terms and conditions of use <Link to="/terms">here</Link>.
              </p>
          </div>
        </div>
      </section>

      <section className='relative'>
        { screenSize ? (
            ( second ? (
                <video className="top-0 z-10 absolute h-912-px no-max" autoPlay loop muted playsInline>
                    <source
                        src={secondVideo}
                        type="video/mp4"
                    />
                    Your browser does not support HTML5 video.
                </video>
                ) : (
                <></>
                )
            )

            ) : (
                <img src={PeopleImage} className="w-full"/>
            )
        }
        <div className={`container mx-auto z-10 h-full flex items-center ${screenSize && 'h-912-px'}`}>
          <div className="flex flex-wrap items-center h-full">
            <div className="w-12/12 lg:w-6/12 md:px-4">

            </div>

            <div className="w-12/12 lg:w-6/12 px-4 py-10 z-40 ">
              <div className="flex flex-wrap items-center z-40">
                <div className="w-full md:w-12/12 z-40">
                  <h3 className="text-blue hl-48">
                    An investment in people
                  </h3>
                  <p className="text-20-30 text-blue-v-dark mb-6 mt-8">
                    Everybody deserves to have access to great financial choices. VELA has enabled
                    qualifying investors to access the same opportunities as leading pan-African
                    banks to make use of JUMO’s market leading lending infrastructure.
                  </p>
                  <p className="text-20-30 text-blue-v-dark mb-6">
                    Capital invested in VELA by JUMO is allocated to small businesses, entrepreneurs
                    and people who need to be able to borrow money at affordable prices, irrespective
                    of their financial status. JUMO focuses on markets where financial inclusion is
                    lagging, with the intention of redressing the issue. It’s about connecting people
                    and capital with trust and integrity.
                  </p>
                  <p className="text-20-30 text-blue-v-dark">
                    An investment in VELA powers mobile financial services for a new generation of
                    emerging market customers in Côte d’Ivoire, Ghana, Kenya, Tanzania, Uganda
                    and Zambia.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 px-8 relative bg-blue">
        { modalIsOpen ? (
            <div className="bg-dark-transparent fixed w-full h-screen flex items-center bottom-0 z-100 right-0">
                <div className="mx-auto rounded-lg h-600-px mt-8">
                    <video width="1000" autoPlay controls playsInline>
                        <source
                            src={modalVideo}
                            type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
                <button className="absolute top-0 right-0 text-white mr-4 mt-4 text-20-30" onClick={onClose}>CLOSE</button>
            </div>
          ) : (
            <></>
          )
        }
        <div className={`container mx-auto ${screenSize && 'py-16'}`}>
          <div className={`mx-auto ${screenSize && 'max-w-60 mb-16'}`}>
              <h3 className="mb-2 hl-48 text-white text-center">
                Sharing the efficiency created by technology
              </h3>
          </div>
          <div className="flex flex-wrap items-center">
            <div className="lg:w-10/12 lg:w-6/12 md:px-12 md:px-4 md:mt-32">
              <p className="mt-4 text-20-30 text-white">
                The VELA investment model is a win for all involved. Backing JUMO's low-cost,
                digital banking infrastructure provides value and efficiency for everyone.
                Entrepreneurs on the ground benefit from lower lending rates and convenience,
                while capital providers or investors are rewarded with returns. JUMO's
                banking as a service platform has been built to meet the needs of each
                contributor to the ecosystem.
              </p>
              <div className={`justify-center ${screenSize && 'pt-12'}`}>
                <LogoJumo className="center my-4"/>
              </div>
            </div>

            <div className="w-12/12 lg:w-6/12 md:px-4 md:mt-32" onClick={onOpen}>
              <div className="w-full flex justify-center">
                <div className="w-full md:w-12/12 md:px-4 flex items-center justify-center">
                    <img src={ModalImage} className="bg-dark-blue-v-transparent"/>
                    <VideoPlay className="absolute z-40 size-play-button"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-yellow py-10">
        <div className={`mx-auto px-8 ${screenSize && 'max-w-50 mt-16 mb-16'}`}>
          <h4 className="text-48 text-center text-blue">
            ‘If you want to go fast, go alone. If you want to go far, go together.’
          </h4>
          <p className="text-blue text-20-30 text-center mt-10">
            African Proverb
          </p>
        </div>
      </section>

      <section className="py-16 relative grad-1">

        <div className={`container mx-auto text-center ${screenSize && 'mt-8 mb-12'}`}>
            <h3 className="text-40 text-white mb-2">
              A track record of strong delivery
            </h3>
            <p className="text-white text-20-30">
                Matured facilities repaid since 2019
            </p>
        </div>
        <div className="container-large mx-auto">
            <HomeCarousel style={{ width: "100vh" }} />
        </div>

        <div className={`container mx-auto ${screenSize && 'mt-24'}`}>
          <div className="justify-center flex flex-wrap">
            <div className="container mx-auto text-center">
                <h3 className="text-40 text-white mb-2">
                  Growing with the flow
                </h3>
                <p className={`text-white text-20-30 ${screenSize && 'mb-40'}`}>
                    Active facilities closed for investment
                </p>
            </div>
            <div className="w-full lg:w-12/12 mt-10">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white-10 h-332 w-full mb-6 rounded-lg">
                    <div className="w-full lg:w-12/12 pt-4 px-6">
                        <div className="flex items-center h-170 py-2">
                          <div className="w-4/12">
                            <BTCVenn className="w-60-px mx-auto"/>
                            <div className="bg-white rounded-sm mt-8">
                              <h2 className="text-12-600 text-blue text-center"> CLOSED </h2>
                            </div>
                          </div>
                          <div className="w-8/12 px-6 py-4">
                            <h1 className="text-24-600 text-yellow">Azure Fleet</h1>
                            <p className="text-12-500 text-yellow pb-4">Aware ESG facility, capped Bitcoin growth</p>
                            <p className="text-24-800 text-white">10.00%</p>
                            <p className="text-12-500 text-white ">Interest per Annum</p>
                          </div>
                        </div>
                    </div>

                    <hr className="mb-4 mt-2 bigHr"></hr>

                    <p className="px-6 pb-4 text-16-150 text-white">
                      Duration <span className="text-16-600 text-right">10 months</span>
                    </p>
                    <p className="px-6 pb-4 text-16-150 text-white">
                      Jurisdiction <span className="text-16-600 text-right">Pan African</span>
                    </p>
                    <p className="px-6 pb-4 text-16-150 text-white">
                      Currency <span className="text-16-600 text-right">BTC</span>
                    </p>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white-10 h-332 w-full mb-6 rounded-lg">
                    <div className="w-full lg:w-12/12 pt-4 px-6">
                        <div className="flex items-center h-170 py-2">
                          <div className="w-4/12">
                            <BTCVenn className="w-60-px mx-auto"/>
                            <div className="bg-white rounded-sm mt-8">
                              <h2 className="text-12-600 text-blue text-center"> CLOSED </h2>
                            </div>
                          </div>
                          <div className="w-8/12 px-6 py-4">
                            <h1 className="text-24-600 text-yellow">Azure Arc</h1>
                            <p className="text-12-500 text-yellow pb-4">Aware ESG facility, uncapped Bitcoin growth</p>
                            <p className="text-24-800 text-white">3.00%</p>
                            <p className="text-12-500 text-white ">Interest per Annum</p>
                          </div>
                        </div>
                    </div>

                    <hr className="mb-4 mt-2 bigHr"></hr>

                    <p className="px-6 pb-4 text-16-150 text-white">
                      Duration <span className="text-16-600 text-right">18 months</span>
                    </p>
                    <p className="px-6 pb-4 text-16-150 text-white">
                      Jurisdiction <span className="text-16-600 text-right">Pan African</span>
                    </p>
                    <p className="px-6 pb-4 text-16-150 text-white">
                      Currency <span className="text-16-600 text-right">BTC</span>
                    </p>
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white-10 h-332 w-full mb-6 rounded-lg">
                    <div className="w-full lg:w-12/12 pt-4 px-6">
                        <div className="flex items-center h-170 py-2">
                          <div className="w-4/12">
                            <Venn className="w-60-px mx-auto"/>
                            <div className="bg-white rounded-sm mt-8">
                              <h2 className="text-12-600 text-blue text-center"> CLOSED </h2>
                            </div>
                          </div>
                          <div className="w-8/12 px-6 py-4">
                            <h1 className="text-24-600 text-yellow">Cobalt Crest</h1>
                            <p className="text-12-500 text-yellow pb-4">Sustainabilty ESG facility</p>
                            <p className="text-24-800 text-white">14.44%</p>
                            <p className="text-12-500 text-white ">Interest per Annum</p>
                          </div>
                        </div>
                    </div>

                    <hr className="mb-4 mt-2 bigHr"></hr>

                    <p className="px-6 pb-4 text-16-150 text-white">
                      Duration <span className="text-16-600 text-right">13.8 months</span>
                    </p>
                    <p className="px-6 pb-4 text-16-150 text-white">
                      Jurisdiction <span className="text-16-600 text-right">Pan African</span>
                    </p>
                    <p className="px-6 pb-4 text-16-150 text-white">
                      Currency <span className="text-16-600 text-right">USD / GBP / EUR</span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className={`mx-auto px-8 ${screenSize && 'max-w-80 mt-16 mb-24'}`}>
            <p className="text-20-30 text-white text-center md:mt-32 py-10">
              Would you like to follow VELA’s performance and help us shape the journey
              to advance financial inclusion? The aim is to create one of the biggest
              impact platforms in the world. Past investors have received attractive
              returns at the same time as supporting measurable impact!
            </p>
            <div className={`flex justify-center ${screenSize && 'mt-4'}`}>
                <Link
                  className="get-started text-blue text-16-19-bold px-8 py-3 rounded mb-1 bg-yellow "
                  to="/register"
                >
                  Join our journey
                </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

