/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import Widget from "../components/Research/Widget.js";

import WhiteNavbar from "../components/Navbars/WhiteNavbar.js";

import '../assets/styles/App.css';
import "@fontsource/montserrat";


export default function MarketResearch() {
  document.title = "VELA - Market research";
  return (
    <>
        <section>
            <WhiteNavbar />
        </section>

        <section>
            <div className="container mx-auto md:bg-light-grey rounded-lg mb-24">
                <Widget />
            </div>
        </section>
    </>
  );
}

