/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import WhiteNavbar from "../components/Navbars/WhiteNavbar.js";
import Footer from "../components/Footers/Footer.js";

import '../assets/styles/App.css';
import '../assets/styles/tailwind.css';
import "@fontsource/montserrat";


export default function Index() {
  window.scrollTo(0, 0);

  document.title = "VELA - Cookie Policy";
  return (
    <>
      <section>
        <WhiteNavbar />
      </section>

      <section className="relative">
        <div className="container mx-auto mb-24 px-4">
          <h1 className="hl-48 md:text-center mt-12 mb-8">
            Cookie Policy
          </h1>
          <p className="text-16-150 mb-4">
            Welcome to JUMO World’s Cookie Policy. This Cookie Policy applies between JUMO World Limited and all its affiliated companies (“JUMO”, “we”, “us” or “our”) and you when you use this site (“Site”).
          </p>

          <h4 className="text-24-500 mt-8 mb-4">
            What are cookies?
          </h4>
          <p className="text-16-150 mb-4">
            Cookies are harmless text files that web servers can store on your computer’s hard drive when you visit a website. They allow the server to recognise you when you revisit.  These files hold small amounts of data and allow the server to recognise you when you revisit a website.
          </p>

          <h4 className="text-24-500 mt-8 mb-4">
            Why we use cookies
          </h4>
          <p className="text-16-150 mb-4">
            We use cookies on our Site to gain insight into how our visitors use the Site and to improve our Site service and to contribute to research and analytics studies, including the use of artificial intelligence and machine learning. Internet cookies are common and do not harm your system – they just store or gather site information. They help you do things online, like remembering login details so you don’t have to re-enter them when revisiting a site.
          </p>

          <h4 className="text-24-500 mt-8 mb-4">
            How we use cookies
          </h4>
          <p className="text-16-150 mb-4">
            We use cookies to:
            <ul className='circle'>
                <li>Gather customer journey information on our public site only</li>
                <li>Temporarily store input information in our calculators, tools, illustrations and demonstrations</li>
                <li>Store details of your marketing, product preferences to improve our targeting and enhance your journey through our sites and partner sites</li>
                <li>Evaluate our sites’ advertising and promotional effectiveness (we own the anonymous data collected and don’t share it with anyone)</li>
            </ul>
          </p>
          <p className="text-16-150 mb-4">
            We don’t use cookies to track people’s Internet usage after leaving our sites and we don’t store personal information in them that others could read and understand. We will not sell or distribute cookie information without your prior consent.
          </p>

          <h4 className="text-24-500 mt-8 mb-4">
            What cookies do we use?
          </h4>
          <p className="text-16-150 mb-4">
            We use both:
            <ul className='circle'>
                <li>Session cookies– a session cookie (also called a transient cookie) is temporary and will be erased once you close your browser (session cookies will not gather information from your computer, but they will recognise you as you navigate between pages during your session and will help maintain security during your visit); and</li>
                <li>Persistent cookies– persistent cookies are permanent cookies that have set expiration dates, they are stored on your device until they expire or are deleted and they will collect information such as your login details or user preferences when you visit certain websites.</li>

            </ul>
          </p>
          <p className="text-16-150 mb-4">
            Cookies can also be either:
            <ul className='circle'>
                <li>First-party cookies– a first-party cookie is owned and created by the Site you are visiting and stores a small amount of text on your computer (first-party cookies are usually allowed by most web browsers, but if you should choose to disable these type of cookies, the Site you are visiting will not be able to track your activity during navigation); or</li>
                <li>Third-party cookies – third-party cookies are created and owned by a website other than the one you are currently visiting, these cookies will usually be from a company providing a service to the owners of the website you are visiting and these type of cookies typically store tracking information and personal preferences.</li>

            </ul>
          </p>
          <p className="text-16-150 mb-4">
            We use both first-party and third-party cookies on our Site. Please consult the privacy policies of third parties, such as Google, Facebook or LinkedIn, to learn more about how they use information that they may collect via our Site.
          </p>

          <h4 className="text-24-500 mt-8 mb-4">
            Changing your cookies settings
          </h4>
          <p className="text-16-150 mb-4">
            To enable or disable cookies, follow the instructions provided by your browser (usually located within the ‘Help’, ‘Tools’ or ‘Edit’ facility). Alternatively, an external resource is available at <a href="https://allaboutcookies.org/">All about cookies</a> providing specific information about cookies and how to manage them.
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
}
