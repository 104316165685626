import React from 'react';

import SignUp from "../components/Authentication/SignUp.js";
import { Account } from "../components/Authentication/Account";
import Status from "../components/Authentication/Status";

import '../assets/styles/App.css';
import "@fontsource/montserrat";

const Register = () => {
    document.title = "VELA - Register an account";
    return (
        <Account>
            <Status />
            <SignUp />
        </Account>
    )
}

export default Register;
