import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "./Account";
import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";

export default () => {
    const { getSession } = useContext(AccountContext);
    const [loggedin, setLoggedIn] = useState(false);

    useEffect(() =>{
        getSession().then(() => {
            setLoggedIn(true);
        }).catch(err =>{
            setLoggedIn(false);
        });
    }, []);

    return (
        <div>
            {loggedin && (
                <>
                    <h2>Settings</h2>
                    <ChangePassword />
                    <ChangeEmail />
                </>
            )}
        </div>
    );
}