import React, { useState } from "react";

import ReactGA from "react-ga4";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "../../assets/styles/tailwind.css";


const GATrackingID = "G-86QYJBNVCR";
ReactGA.initialize(GATrackingID);


export default function CookiePreferences(props) {
    const [modalIsOpen, setIsOpen] = useState(props.isOpen);

    const onSubmit = (event) => {
        event.preventDefault();
        setIsOpen(false);
    };
    return (
        <>
            { modalIsOpen ? (
                <div className="fixed w-full flex h-screen bottom-0 z-100 px-4">
                    <div className="w-full h-full flex items-center">
                        <div className="mx-auto bg-white rounded-lg h-400-px max-w-600-px">
                            <div className="py-10 rounded-t-lg">
                                <h2 className="text-24-150 text-blue-v-dark text-center">Cookie Preferences</h2>
                            </div>
                            <form onSubmit={onSubmit}>
                                <div className="py-10 px-12">
                                    <div className="mb-6">
                                        <label className="text-16-150-change text-blue-v-dark">
                                            <input type="checkbox" />
                                            <span class="slider round"></span>
                                            Functional
                                        </label>
                                    </div>

                                    <div className="mb-6">
                                        <label className="text-16-150-change text-blue-v-dark">
                                            <input type="checkbox" />
                                            <span class="slider round"></span>
                                            Functional
                                        </label>
                                    </div>

                                    <div className="mb-6">
                                        <label className="text-16-150-change text-blue-v-dark">
                                            <input type="checkbox" />
                                            <span class="slider round"></span>
                                            Functional
                                        </label>
                                    </div>

                                    <div className="flex justify-center mt-8">
                                        <button
                                            className="bg-yellow mx-auto text-16-19 text-blue-v-dark py-2 px-12 rounded"
                                            type="submit"
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
              ) : (
                <></>
              )
            }
        </>
    );
}