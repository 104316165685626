/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// components
import UserDropdown from "../../components/Dropdowns/UserDropdown.js";
import { ReactComponent as HorizontalLogoBlue } from '../../assets/img/Vela_horizontal_blue.svg';

// styles
import '../../assets/styles/App.css';


export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 relative w-full flex flex-wrap items-center justify-between px-2 py-4 navbar-expand-lg text-white bg-white">
        <div className="container px-4 py-2 mx-auto rounded flex flex-wrap items-center justify-between navbar-white">
          <div className="w-full relative flex justify-between items-center lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className="leading-relaxed inline-block mr-4 pr-10"
              to="/"
            >
            { window.innerWidth < 768 ? (
                <HorizontalLogoBlue className="size-horizontal-logo-small" />
                ) : (
                <HorizontalLogoBlue className="size-horizontal-logo-large" />
                )
            }

            </Link>
            <button
              className="cursor-pointer leading-none px-3 py-2 border-0 rounded-full bg-yellow block lg:hidden outline-none h-burger"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="text-blue fas fa-bars"></i>
            </button>
          </div>

          <div
            className={
              "lg:flex flex-grow items-center center md:bg-white lg:bg-opacity-0 " +
              (navbarOpen ? "block mt-8" : " hidden")
            }
            id="example-navbar-warning">
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto"
          >

              <li className="flex items-center hover:text-light-blue">
                <Link
                  className="text-16-500 py-3 inline-block mx-4 whitespace-nowrap"
                  to="/"
                >
                  About Us
                </Link>
              </li>

              <li className="flex items-center hover:text-light-blue">
                <Link
                  className="text-16-500 py-3 inline-block mx-4 whitespace-nowrap"
                  to="/portfolio-performance"
                >
                  Performance
                </Link>
              </li>

              <li className="flex items-center hover:text-light-blue">
                <a
                  className="text-16-500 py-3 inline-block mx-4 whitespace-nowrap"
                  href="https://jumo.world/"
                  target="_blank"
                >
                  JUMO
                </a>
              </li>

            </ul>
          </div>

          <div
            className={
              "lg:flex flex-grow items-center lg:bg-opacity-0" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            { status ? (
                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                  <li className="flex items-center">
                    <UserDropdown logout={logout}/>
                  </li>
                </ul>
                ) : (

                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto mt-2">
                  <li className="flex items-center hover:text-light-blue">

                        <div className="items-center flex">
                          <Link
                              className={`text-16-500 py-2 hover:text-light-blue lg:mb-0 + ${navbarOpen ? "text-blue" : "text-yellow"}`}
                              to="/login"
                          >
                              <button
                                  className={"text-16-500 px-12 py-3 ml-3 mb-3 flex items-center rounded text-blue"
                                             + (navbarOpen ? "bg-yellow rounded" : "")}
                                  type="button"
                                >
                                   &nbsp;&nbsp; Sign In &nbsp;&nbsp;
                              </button>
                          </Link>
                        </div>

                  </li>
                </ul>

            )}
          </div>
        </div>
      </nav>
    </>
  );
}
