/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";

import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import Footer from "../components/Footers/Footer.js";

import '../assets/styles/App.css';
import '../assets/styles/tailwind.css';
import "@fontsource/montserrat";

import CardBTCFundDetails from "../components/Cards/CardBTCFundDetails.js";
import CardFiatFundDetails from "../components/Cards/CardFiatFundDetails.js";
import CardInactiveFundDetails from "../components/Cards/CardInactiveFundDetails.js";

import { AccountContext } from "../components/Authentication/Account.js";
import AuthHeader from "../components/Authentication/AuthHeader.js";

export default function PerformanceDashboard(props) {

    const [activeFunds, setActiveFunds] = useState([]);
    const [inactiveFunds, setInactiveFunds] = useState([]);
    const { getSession } = useContext(AccountContext);

    document.title = "VELA - Performance";

    useEffect(() => {
        getSession().then(session => {
            fetch('/api/fund-overview',
                    {
                        method: "GET",
                        headers: {"Content-Type": "application/json", "Authorization": AuthHeader(session)}
                    })
              .then(res => res.json())
              .then(data => {
                setActiveFunds(data.active)
                setInactiveFunds(data.inactive)
            });
        })
    }, []);

  return (
    <>
        <section>
          <AuthNavbar />
        </section>

        <section className="relative grad-1">
            <div className="container relative mx-auto">
                <h1 className="text-white hl-48 pt-16 px-6">Performance</h1>
                <h4 className="text-yellow text-36-500 mt-4 md:mt-16 px-6">Closed facilities</h4>
                <p className="text-white text-20-30 mt-8 mb-6 px-6">
                    These  closed facilities are fully funded and currently active. Click
                    on reporting to track their performance.
                </p>

                {
                    (activeFunds.length > 0) ?
                       activeFunds.map(fund => (
                            (fund.fund_is_btc)? <CardBTCFundDetails fund={fund} hasDetailsButton={true} /> :
                            <CardFiatFundDetails fund={fund} hasDetailsButton={true} />
                        )) : <div className="container relative mx-auto px-6">
                                <p className="text-white text-20-30 mt-8">
                                    <b className="text-24-active-funds">No Active Facilities</b>
                                </p>
                             </div>
                }


            </div>

        </section>

        <section className="py-10 relative bg-blue-5">
            <div className="container center">
              <h4 className={`text-yellow text-36-500 px-6 ${(window.innerWidth >= 768) && 'mt-16'}`}>Past investments</h4>
              <p className="text-white text-20-30 mt-8 mb-4 px-6">
                VELA has enabled qualifying investors to access the same opportunities
                as leading pan-African banks to make use of JUMO’s market leading
                lending infrastructure.
              </p>

              <div className="justify-center flex flex-wrap">
                <div className="w-full lg:w-12/12 md:mt-24">
                  <div className="flex flex-wrap">

                  {inactiveFunds.length > 0 ?
                    inactiveFunds.map(fund => (
                    <CardInactiveFundDetails fund={fund} />
                    )) : <div className="container relative mx-auto">
                            <p className="text-white text-20-30 mt-8">
                                <b className="text-24-active-funds px-6">No Inactive Facilities</b>
                            </p>
                         </div>
                   }

                  </div>
                </div>
              </div>
            </div>
        </section>



        <Footer />
    </>
    );
  }