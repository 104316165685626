import React, { useState, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AccountContext } from "./components/Authentication/Account.js";

export const AdminRoute = ({ component: Component, ...rest }) => {
    const [status, setStatus] = useState({});
    const [admin, setAdmin] = useState({});
    const { getSession } = useContext(AccountContext);


    getSession().then(session => {
        var sessionPayload = session.accessToken.payload
        if (sessionPayload.hasOwnProperty('cognito:groups')){
            var userGroups = sessionPayload['cognito:groups']
            if (userGroups.includes("vela-admin")) {
                setAdmin(true)
            }
        } else {
            setAdmin(false)
        }
        setStatus(true);
    }).catch(err =>{
        setAdmin(false);
        setStatus(false);
    });

    return (
        <Route
            {...rest}
            render={ props => {
                if (admin === false) {
                    return <Redirect to='/login' />;
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    );
};