import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";

const Status = () => {
    const [status, setStatus] = useState(false);
    const { getSession, logout } = useContext(AccountContext);

    useEffect(() => {
        getSession().then(session => {
            setStatus(true);
        }).catch(err =>{
            setStatus(false);
        });
    }, []);

    return (<></>);
};

export default Status;