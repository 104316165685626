import React, { useState, useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AccountContext } from "./components/Authentication/Account.js";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const [status, setStatus] = useState({});
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession().then(session => {
            setStatus(true);
        }).catch(err =>{
            setStatus(false);
        });
    }, []);

    return (
        <Route
            {...rest}
            render={ props => {
                if (status) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to='/register' />;
                }
            }}
        />
    );
};