import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as HorizontalLogo } from '../../assets/img/Vela_horizontal.svg';
import { ReactComponent as LargeLogo } from '../../assets/img/logo_large_new.svg';

import '../../assets/styles/App.css';

export default function Footer() {
  return (
    <>
    { (window.innerWidth < 912) ? (
        <footer className="relative bg-blue pt-8 pb-6">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
              <div className="w-full flex justify-center">
                <Link
                  className="text-white leading-relaxed inline-block py-2 whitespace-nowrap"
                  to="/"
                >
                  <LargeLogo className="size-large-logo-max mt-4 mb-4"/>
                </Link>
              </div>
              <div className="w-full flex justify-center flex-wrap mt-4">

                  <p className="text-center px-12">
                    <Link
                      className="text-16-500 text-white inline-block mx-4 whitespace-nowrap py-3"
                      to="/terms"
                    >
                      Terms Of Use
                    </Link>
                  </p>

                  <p className="text-center px-12">
                    <Link
                      className="text-16-500 text-white inline-block mx-4 whitespace-nowrap py-3"
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </p>

                  <p className="text-center px-12">
                    <Link
                      className="text-16-500 text-white inline-block mx-4 whitespace-nowrap py-3"
                      to="/cookie-policy"
                    >
                      Cookie Policy
                    </Link>
                  </p>

                  <p className="text-center px-12">
                    <a
                      className="text-16-500 text-white inline-block mx-4 whitespace-nowrap py-3"
                      href="https://jumo.world/"
                    >
                      JUMO
                    </a>
                  </p>

              </div>
              <div className="w-full relative mt-4 text-center">
                <p className="text-16-500 text-white py-6">© JUMO</p>
              </div>
            </div>
        </footer>
        ) : (
        <footer className="relative bg-blue pt-8 pb-6">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
              <div className="mr-4">
                <Link
                  className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 pr-10 whitespace-nowrap uppercase"
                  to="/"
                >
                  <HorizontalLogo className="size-horizontal-logo-max"/>
                </Link>
              </div>
              <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start ml-4">
                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">

                  <li className="flex items-center">
                    <Link
                      className="text-16-500 text-white inline-block mx-4 whitespace-nowrap"
                      to="/terms"
                    >
                      Terms Of Use
                    </Link>
                  </li>

                  <li className="flex items-center">
                    <Link
                      className="text-16-500 text-white inline-block mx-4 whitespace-nowrap"
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </li>

                  <li className="flex items-center">
                    <Link
                      className="text-16-500 text-white inline-block mx-4 whitespace-nowrap"
                      to="/cookie-policy"
                    >
                      Cookie Policy
                    </Link>
                  </li>

                  <li className="flex items-center">
                    <a
                      className="text-16-500 text-white inline-block mx-4 whitespace-nowrap"
                      href="https://jumo.world/"
                      target="_blank"
                    >
                      JUMO
                    </a>
                  </li>

                </ul>
              </div>
            </div>
        </footer>
        )
    }


    </>
  );
}