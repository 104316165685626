/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import Footer from "../components/Footers/Footer.js";

import CardBTCFundDetails from "../components/Cards/CardBTCFundDetails.js";
import CardFiatFundDetails from "../components/Cards/CardFiatFundDetails.js";
import CardFiatReport from "../components/Cards/CardFiatReport.js";
import CardFacilityReport from "../components/Cards/CardFacilityReport.js";
import CardMarginTarget from "../components/Cards/CardMarginTarget.js";
import CardError from "../components/Cards/CardError.js";

import { AccountContext } from "../components/Authentication/Account.js";
import AuthHeader from "../components/Authentication/AuthHeader.js"

import '../assets/styles/App.css';
import "@fontsource/montserrat";

import { ReactComponent as BTCGroup } from '../assets/img/Group 71.svg';


export default function FundDashboard() {

  const [fund, setFund] = useState({});
  const [chartData, setChartData] = useState({});
  const [status, setStatus] = useState();
  const [lastUpdate, setLastUpdate] = useState('2022-09-01 00:00');
  const [message, setMessage] = useState();
  const { getSession } = useContext(AccountContext);
  let { facility_code } = useParams();

  document.title = "VELA - Fund performance";

  useEffect(() => {
    getSession().then(session => {
        fetch('/api/fund-data/'+ facility_code,
                {
                    method: "GET",
                    headers: {"Content-Type": "application/json", "Authorization": AuthHeader(session)}
                })
          .then(res => res.json())
          .then(data => {
            if (data) {
                if(data['status'] == 200 && Object.keys(data.fund_details).length > 0
                    && Object.keys(data.report_data).length > 0) {
                        const fundDetails = data.fund_details;
                        setFund(fundDetails);
                        const reportData = data.report_data;
                        setChartData(reportData);
                        setStatus(data.status);
                        setMessage(data.message);
                        setLastUpdate(data.updated_at);
            } else {
                setStatus(data.status);
                setMessage(data.message);
            }
       }
    });
  })}, []);

  return (
    <>
        <section>
          <AuthNavbar />
        </section>

        <section className="relative grad-1 pb-20">
            <div className="container relative mx-auto pt-12 px-4">
                <Link
                  className="get-started text-blue text-16-19 px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-yellow active:bg-blue text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  to="/portfolio-performance"
                >
                  Back to performance
                </Link>
            { (status == 200) ?
                ( fund.fund_is_btc ?
                    (   <>
                            <CardBTCFundDetails fund={fund} hasDetailsButton={false} />

                            <div className="container relative mx-auto items-center rounded-lg mt-24 mb-4 px-4">
                              <h2 className="text-36 text-yellow mb-6">Reports</h2>
                              <p className="text-20-30 text-white">
                                Vela by JUMO is democratizing access to a new asset class, by giving
                                qualifying investors the same opportunities as top tier banks to make
                                use of JUMO’s market leading lending infrastructure.
                              </p>
                            </div>

                            <CardFacilityReport chartData={chartData} />

                            { chartData.show_performance ? (
                                    <CardMarginTarget chartData={chartData} />
                                ) : (
                                    <></>
                                )
                            }

                            <div className="container mx-auto mt-b px-8">
                                <p className="text-white text-16-150 text-right">Last updated: {lastUpdate}</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <CardFiatFundDetails fund={fund} hasDetailsButton={false}/>

                            <div className="container relative mx-auto items-center rounded-lg mt-24 mb-4 px-4">
                              <h2 className="text-36 text-yellow mb-6">Reports</h2>
                              <p className="text-20-30 text-white">
                                Vela by JUMO is democratizing access to a new asset class, by giving
                                qualifying investors the same opportunities as top tier banks to make
                                use of JUMO’s market leading lending infrastructure.
                              </p>
                            </div>

                            <CardFacilityReport chartData={chartData} />

                            <div className="container mx-auto mt-b px-8">
                                <p className="text-white text-16-150 text-right">Last updated {lastUpdate}</p>
                            </div>
                        </>
                    )
                ) : <CardError message={message} />
            }

            </div>

        </section>

        <Footer className="relative"/>
    </>
    );
  }