import React, { useState, useEffect } from "react";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";

import EcosystemChart from "../Charts/EcosystemTransactionValues.js";


export default function CardFacilityReport(props) {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (Object.keys(props.chartData).length > 0) {
        setChartData(props.chartData)
    }
  }, [props.chartData]);
  return (
    <>
        <div className="relative container mx-auto mb-12 mt-8">

            <div className="relative w-full px-4 mb-8">
              <div className="relative bg-white-7 w-full rounded-lg p-4 mb-8">
                <EcosystemChart
                    disbursements={chartData.ecosystem_disbursements}
                    collections={chartData.ecosystem_collections}
                    rollingValues={chartData.ecosystem_rolling_30_day_underwriting_value}
                    labels={chartData.ecosystem_dates}
                    title='Daily Ecosystem Transaction Values'
                />
              </div>
            </div>

            <div className="relative w-full">
                <div className="flex flex-wrap">

                    <div className="w-full md:w-4/12 lg:w-4/12 px-4 mb-4">
                      <div className="relative flex flex-col w-full">
                        <div className="w-full bg-white-7 rounded-lg h-200-px flex items-center px-12">
                            <div className="w-full">
                                <p className="text-yellow text-20-30 mb-4">Ecosystem Lifetime Loans</p>
                                <p className="text-white hl-48">{Math.round(chartData.lifetime_loans / 1e6)}m</p>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-4/12 lg:w-4/12 px-4 mb-4">
                      <div className="relative flex flex-col w-full">
                        <div className="w-full bg-white-7 rounded-lg h-200-px flex items-center px-12">
                            <div className="w-full">
                                <p className="text-yellow text-20-30 mb-4">Ecosystem 30 Day Avg Loan Value</p>
                                <p className="text-white hl-48">${Math.round(chartData.thirty_day_avg_loan_value * 100) / 100}</p>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-4/12 lg:w-4/12 px-4 mb-4">
                      <div className="relative flex flex-col w-full">
                        <div className="w-full bg-white-7 rounded-lg h-200-px flex items-center px-12">
                            <div className="w-full">
                                <p className="text-yellow text-20-30 mb-4">Ecosystem Lifetime Customers</p>
                                <p className="text-white hl-48">{Math.round(chartData.lifetime_customers / 1e5) / 10}m</p>
                            </div>
                        </div>
                      </div>
                    </div>

                </div>
            </div>

        </div>
    </>
  );
}
