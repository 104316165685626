/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

// components
import UserDropdown from "../../components/Dropdowns/UserDropdown.js";
import { ReactComponent as HorizontalLogoWhite } from '../../assets/img/Vela_horizontal.svg';
import { ReactComponent as HorizontalLogoBlue } from '../../assets/img/Vela_horizontal_blue.svg';

import { ReactComponent as UserIcon } from '../../assets/img/profile_icon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/img/down_arrow_icon.svg';

// styles
import '../../assets/styles/App.css';


const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [color, setColor] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY <= 0) { // if scroll down hide the navbar
        setShow(false);
        setColor(false);
      } else if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
        setShow(true);
      } else { // if scroll up show the navbar
        setShow(false);

        if (window.scrollY == 0) { // if scroll down hide the navbar
          setColor(false);
        } else if (window.scrollY < lastScrollY){
          setColor(true);
        } else {
          setColor(false);
        }
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  const smallLogo = (window.innerWidth < 768);
  return (
    <>
      <nav className={`top-0 w-full fixed z-50 flex flex-wrap items-center justify-between px-2 py-4 navbar-expand-lg text-white active ${show && 'hidden'}`}>
        <div className={`container px-4 py-2 mx-auto rounded flex flex-wrap items-center justify-between ${color && 'navbar-white shadow-lg'} ${navbarOpen && 'navbar-white'}`}>
          <div className="w-full relative flex justify-between items-center lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className="leading-relaxed inline-block mr-4 pr-10"
              to="/"
            >
              { (color || navbarOpen ) ? (
                <HorizontalLogoBlue className={`size-horizontal-logo-max ${smallLogo && 'size-horizontal-logo-small'}`} />
              ) : (
                <HorizontalLogoWhite className={`size-horizontal-logo-max ${smallLogo && 'size-horizontal-logo-small'}`} />
              )}

            </Link>
            <button
              className="cursor-pointer leading-none px-3 py-2 border-0 rounded-full bg-yellow block lg:hidden outline-none h-burger"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="text-blue fas fa-bars"></i>
            </button>
          </div>

          <div
            className={
              "lg:flex flex-grow items-center center md:bg-white lg:bg-opacity-0 " +
              (navbarOpen ? "block mt-8" : " hidden")
            }
            id="example-navbar-warning">
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto"
          >

              <li className="flex items-center hover:text-light-blue active:text-light-blue">
                <Link
                  className="text-16-500 py-3 inline-block mx-4 whitespace-nowrap"
                  to="/"
                >
                  About Us
                </Link>
              </li>

              <li className="flex items-center hover:text-light-blue active:text-light-blue">
                <Link
                  className="text-16-500 py-3 inline-block mx-4 whitespace-nowrap"
                  to="/portfolio-performance"
                >
                  Performance
                </Link>
              </li>

              <li className="flex items-center hover:text-light-blue">
                <a
                  className="text-16-500 py-3 inline-block mx-4 whitespace-nowrap"
                  href="https://jumo.world/"
                  target="_blank"
                >
                  JUMO
                </a>
              </li>

            </ul>
          </div>

          <div
            className={
              "lg:flex flex-grow items-center lg:bg-opacity-0" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            { status ? (
                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                  <li className="flex items-center">
                    <UserDropdown logout={logout}/>
                  </li>
                </ul>
                ) : (

                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto mt-2">
                  <li className="flex items-center">

                        <div className="items-center flex">
                          <Link
                              className={`text-16-500 py-2 hover:text-light-blue lg:mb-0 + ${(navbarOpen || color ) ? "text-blue" : "text-yellow"}`}
                              to="/login"
                          >
                              <button
                                  className={"text-16-500 px-12 py-3 ml-3 mb-3 flex items-center rounded"
                                             + (navbarOpen ? "text-blue bg-yellow rounded" : "text-yellow")}
                                  type="button"
                                >
                                   &nbsp;&nbsp; Sign In &nbsp;&nbsp;
                              </button>
                          </Link>
                        </div>

                  </li>
                </ul>

            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;