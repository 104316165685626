import React from "react";
import { useHistory } from 'react-router-dom';

import { createPopper } from "@popperjs/core";

import { ReactComponent as UserIcon } from '../../assets/img/profile_icon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/img/down_arrow_icon.svg';

import '../../assets/styles/App.css';

const UserDropdown = (props) => {
  const history = useHistory();

  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);

  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleLogout = (props) => {
    const b = props.logout;
    const a = history.push('/');
  };

  return (
    <>
      <a
        className="text-blue block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <button
              className={`text-blue bg-yellow text-16-500 px-6 py-3 w-200-px lg:mr-1 lg:mb-0 mb-3 flex items-center rounded-t ${!dropdownPopoverShow && 'rounded-b'} `}
              type="button"
            >
              <i className="fas"><UserIcon /></i> &nbsp;&nbsp; SIGNED IN &nbsp;&nbsp; <i className="fas"><ArrowIcon /></i>
          </button>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-yellow py-1 w-200-px z-50 text-left rounded-b min-w-48"
        }
      >
        <hr className="dropdown-hr"></hr>
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap text-blue"
          }
          onClick={handleLogout}
        >
          Logout
        </a>
      </div>
    </>
  );
};

export default UserDropdown;