import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";

export default function RegionChart(props) {
  const [chart, setChart] = useState({});
  const canvas = useRef(null);

  const screenSize = (window.innerWidth >= 768);

  useEffect(() => {
      const config = {
          type: 'doughnut',
          data: {
            labels: props.labels,
            datasets: [{
                backgroundColor: ['rgba(255, 196, 0, 1)', 'rgba(0, 116, 224, 1)'],
                borderColor: 'rgba(255, 255, 255, 0)',
                data: props.data,
              },
            ],
          },
          options: {
            plugins: {
                legend: {
                    labels: {
                        color: 'rgb(255, 255, 255)'
                    }
                }
            },
            maintainAspectRatio: false,
            responsive: true,
            title: {
              display: false,
              text: "Orders Chart",
            },
            legend: {
              display: true,
              position: 'right',

              labels: {
                padding: 30,
                fontColor: 'white',
                pointStyle: 'circle',
                usePointStyle: true,
                }
            },
            tooltips: {
              mode: "index",
              intersect: false,
            },
            hover: {
              mode: "nearest",
              intersect: true,
            },
          },
        };

      setChart(new Chart(canvas.current, config));
  }, [props.labels, props.data, props.title, props.background, props.border]);
  return (
    <>
      <div className="w-full h-full flex flex-col justify-center">
        <p className={`text-yellow text-20-30 mb-2 ${screenSize && 'px-8'}`}>Regional Lifetime Allocation</p>
        <div className="h-full max-w-400-px flex items-center">
            <div className="relative h-250-px w-full">
                <canvas ref={canvas} ></canvas>
            </div>
        </div>
      </div>
    </>
  );
}
