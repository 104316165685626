import React, { useState, useContext, useEffect } from "react";
import { useLocation, useHistory, Link } from 'react-router-dom';
import { AccountContext } from "../components/Authentication/Account";

import WhiteNavbar from "../components/Navbars/WhiteNavbar.js";

import '../assets/styles/App.css';
import "@fontsource/montserrat";


function Login() {
    const history = useHistory();
    const location = useLocation();

    const [showMessage, setShowMessage] = useState(false);
    const[email, setEmail] = useState("");
    const[password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState('');

    const { authenticate } = useContext(AccountContext);

    document.title = "VELA - Login";

    useEffect(() => {
        if (typeof location.state !== 'undefined') {
            setShowMessage(true);
        }
    }, []);

    const onSubmit = (event) => {
        event.preventDefault();

        authenticate(email, password)
            .then(data => {
                if (data) {
                    history.push('/portfolio-performance');
                }
            })
            .catch(err => {
                setErrorMessage(err.message);
                console.error('ERROR', err);
            });
    };

    return (
        <>
          <section>
            <WhiteNavbar />
          </section>

          <section>
              <div className="container mx-auto px-4">
                  { showMessage ? (
                    <div className='bg-yellow rounded py-4 px-8'>
                      <p className="text-blue-v-dark text-16-150">We just sent a verification email to the email address you used to sign up. Please click the link in the email to verify your account. If you did not see the verification email, be sure to check your spam folder.</p>
                    </div>
                    ) : (
                    <></>
                    )
                  }

              </div>
              <div className="mx-auto max-w-600-px p-8">

                <div className="text-center">
                    <h1 className="hl-48 text-blue mb-4 mt-16">Welcome to VELA</h1>
                    <p className="text-20-30 text-blue-v-dark mb-12">Sign into your account</p>
                </div>
                <form className="" onSubmit={onSubmit}>
                    <div>
                        <label className="text-16-600 text-blue-v-dark" htmlFor="email">Email address *</label>
                    </div>
                    <div className="mb-4">
                        <input
                            className="h-45-px w-full border-light-grey rounded"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}>
                        </input>
                    </div>
                    <div>
                        <label className="text-16-600 text-blue-v-dark" htmlFor="password">Password *</label>
                    </div>
                    <div className="mb-4">
                        <input
                            className="h-45-px w-full border-light-grey rounded"
                            type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}>
                        </input>
                    </div>
                    {errorMessage && (
                          <div className="form-error"> {errorMessage} </div>
                     )}

                    <div className="flex justify-center mt-12 mb-6 ">
                        <button
                            className="bg-blue-sky mx-auto w-full text-16-19 text-white py-3 px-12 rounded"
                            type="submit"
                        >
                            Sign In
                        </button>
                    </div>
                </form>
                <div className="flex">
                    <p className="text-16-150-500 text-blue-v-dark">Dont have an account? &thinsp;</p>
                    <Link
                        className="text-16-150-500 text-blue-link"
                        to="/register"
                    >
                    Sign up
                    </Link>
                </div>
              </div>
          </section>
        </>
    )
}

export default Login;