import React, { useState, useEffect } from "react";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";

import LifetimeChart from "../Charts/LifetimeAssetsGenerated.js";
import RegionChart from "../Charts/RegionBreakdown.js";


export default function CardFiatReport(props) {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (Object.keys(props.chartData).length > 0) {
        setChartData(props.chartData)
    }
  }, [props.chartData]);
  return (
    <>
        <div className="relative container mx-auto mt-4">

            <div className="relative w-full px-4 mb-8">
              <div className="relative bg-white-7 w-full rounded-lg p-4 mb-8">
                <LifetimeChart
                    assetsUnderwritten={chartData.lifetime_assets_lifetime_gross_assets_underwritten}
                    lifetimeLoans={chartData.lifetime_assets_lifetime_loans}
                    labels={chartData.lifetime_assets_dates}
                    title='Facility Lifetime Assets Generated'
                />
              </div>
            </div>
            <div className="relative w-full">
                <div className="flex flex-wrap">

                    <div className="w-full md:w-6/12 lg:w-6/12 px-4">
                      <div className="relative flex flex-col text-white bg-white-7 w-full mb-8 rounded-lg p-4 h-421">
                        <RegionChart
                            labels={chartData.region_names}
                            data={chartData.region_disbursed_amount_usd}
                            title='Regional Lifetime Allocation'
                        />
                      </div>
                    </div>

                    <div className="w-full md:w-6/12 lg:w-6/12 px-4">
                      <div className="relative flex flex-col w-full mb-6 h-421">
                        <div className="w-full bg-white-7 rounded-lg h-200-px flex items-center px-12 mb-8">
                            <div className="w-full">
                                <p className="text-yellow text-20-30 mb-4">Facility Annual Capital Velocity</p>
                                <p className="text-white hl-48">{chartData.annual_capital_velocity}</p>
                            </div>
                        </div>
                        <div className="w-full bg-white-7 rounded-lg h-200-px flex items-center px-12">
                            <div className="w-full">
                                <p className="text-yellow text-20-30 mb-4">Facility Average Loan Tenor</p>
                                <p className="text-white hl-48">{chartData.average_loan_tenor} <span>days</span></p>
                            </div>
                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </div>
    </>
  );
}
