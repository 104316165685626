/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import Footer from "../components/Footers/Footer.js";

import '../assets/styles/App.css';
import "@fontsource/montserrat";

export default function Error404() {
  return (
    <>
        <section>
          <AuthNavbar />
        </section>

        <section className="relative grad-1 pb-20">
            <div className="container relative mx-auto items-center bg-white rounded-lg">
              <h3 className="text-24-500 text-blue text-center py-16">Page not Found</h3>
              <br/>
            </div>
        </section>
        <Footer />
    </>
    );
  }