/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import CardBTCFundDetails from "../components/Cards/CardBTCFundDetails.js";
import CardFiatFundDetails from "../components/Cards/CardFiatFundDetails.js";
import CardFiatReport from "../components/Cards/CardFiatReport.js";
import CardFacilityReport from "../components/Cards/CardFacilityReport.js";
import CardError from "../components/Cards/CardError.js";

import '../assets/styles/App.css';
import "@fontsource/montserrat";

import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import Footer from "../components/Footers/Footer.js";

import AcceptData from "../components/Events/AcceptData.js";

import { AccountContext } from "../components/Authentication/Account.js";
import AuthHeader from "../components/Authentication/AuthHeader.js"


export default function Index() {
  const [funds, setFunds] = useState([]);
  const [status, setStatus] = useState();
  const [message, setMessage] = useState();
  const { getSession } = useContext(AccountContext);

  document.title = "VELA - Fund performance admin";

  useEffect(() => {
    getSession().then(session => {
        fetch('/api/fund-data/unapproved',
                {
                    method: "GET",
                    headers: {"Content-Type": "application/json", "Authorization": AuthHeader(session)}
                })
          .then(res => res.json())
          .then(data => {
            setFunds(data.funds);
            setStatus(data.status);
            setMessage(data.message);
        }).catch(err => {
            setStatus(400);
            setMessage(err);
            console.error('ERROR', err);
    });
  })}, []);

  return (
    <>
        <section>
          <AuthNavbar />
        </section>

        <section className="relative grad-1 pb-20">
            <div className="container relative mx-auto pt-12">
                <Link
                  className="get-started text-blue text-16-19 px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-yellow active:bg-blue text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  to="/portfolio-performance"
                >
                  Back to Investments
                </Link>
            </div>
            {(status == 200) ?
                ( funds.map(fund => (
                    (fund.fund_details.fund_is_btc) ?
                        <>
                            <CardBTCFundDetails fund={fund.fund_details} hasDetailsButton={false} />

                            <div className="container mx-auto">
                                <p className="text-16-150 text-white my-4 text-right">*Projected returns calculated at current BTC value.</p>
                            </div>

                            <CardFacilityReport chartData={fund.report_data} />
                        </>
                        :
                        <>
                            <CardFiatFundDetails fund={fund.fund_details} hasDetailsButton={false}/>
                            <CardFacilityReport chartData={fund.report_data} />
                        </>

                ))) : <CardError message={message} />
            }

            {(status == 200) &&
                    <div className="container relative mx-auto mt-12">
                        <AcceptData />
                    </div>
            }

        </section>

        <Footer />
    </>
    );
  }