/*eslint-disable*/
import React, { useState, UseEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from 'styled-components';

import ReactSlider from 'react-slider';

import '../../assets/styles/App.css';
import "@fontsource/montserrat";


export default function Widget(props) {
  const location = useLocation();
  const history = useHistory();

  const [percentage, setPercentage] = useState([33, 66]);

  const StyledSlider = styled(ReactSlider)`
    width: 95%;
    height: 15px;
  `;

  const StyledThumb = styled.div`
    top: -8px;
    height: 30px;
    line-height: 30px;
    width: 30px;
    background-color: #d4d4d4;
    border-color: #000000;
    border-radius: 50%;
    cursor: grab;
  `;

  const Thumb = (props, state) => <StyledThumb {...props}></StyledThumb>;

  const StyledTrack = styled.div`
      top: 0;
      bottom: 0;
      background: ${props => (props.index === 2 ? '#0074DE' : props.index === 1 ? '#FFE601' : '#010335')};
      border-radius: 999px;
  `;

  const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

  const onSubmit = (event) => {
        event.preventDefault();

        fetch("/api/survey", {
                method: "POST",
                headers: {"content_type":"application/json",},
                body: JSON.stringify({
                                    "cognito_id": location.state.sub,
                                    'data':
                                        [
                                        {
                                        "question_id": "investment-1mil-cash",
                                        "user_answer_type": "integer",
                                        "user_answer": percentage[0]
                                        },
                                        {
                                        "question_id": "investment-1mil-bitcoinA",
                                        "user_answer_type": "integer",
                                        "user_answer": percentage[1] - percentage[0]
                                        },
                                        {
                                        "question_id": "investment-1mil-bitcoinB",
                                        "user_answer_type": "integer",
                                        "user_answer": 100 - percentage[1]
                                        },
                                        ]
                                        })
                }
        )

        history.push({
            pathname: '/login',
            state: {verification: true}
        });
  };

  return (
    <>
        { (window.innerWidth < 768) ? (
                <div className="md:px-8">
                  <div className="py-12 px-6 md:mt-8 md:mb-12 text-center">
                    <p className="text-blue text-36-widget md:mb-8">
                        Before we continue, please tell us how you would allocate a $100,000
                        investment across the following three funds
                    </p>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="w-full lg:w-12/12 px-1 md:mt-8">
                        <div className="flex pt-6 pb-2">

                            <div className="w-full lg:w-4/12 px-1">
                                <div className="relative bg-blue-v-dark w-full mb-4 rounded-md p-2">
                                    <div className="py-4 text-center">
                                        <p className="text-24-150-600 text-yellow mb-4">Cash</p>
                                        <p className="text-18-400 text-yellow">CURRENCY</p>
                                        <p className="text-18-700 text-yellow mb-4">USD EUR GBP</p>
                                        <p className="text-18-400 text-yellow">TERM</p>
                                        <p className="text-18-700 text-yellow mb-4">12-24 months</p>
                                        <p className="text-18-400 text-yellow">INTEREST</p>
                                        <p className="text-18-700 text-yellow mb-4">15%</p>
                                        <p className="text-18-400 text-blue">CAPITAL GUARANTEED IN USD</p>
                                        <p className="text-18-700 text-blue mb-4">-</p>
                                        <p className="text-18-400 text-blue">BTC APPRECIATION</p>
                                        <p className="text-18-700 text-blue">-</p>
                                    </div>
                                    <div className="py-4 text-center">
                                        <p className="hl-55 text-yellow">{percentage[0]}%</p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-1">
                                <div className="relative bg-yellow w-full mb-4 rounded-md p-2">
                                    <div className="py-4 text-center">
                                        <p className="text-24-150-600 text-blue-v-dark mb-4">Bitcoin A</p>
                                        <p className="text-18-400 text-blue-v-dark">CURRENCY</p>
                                        <p className="text-18-700 text-blue-v-dark mb-4">Bitcoin</p>
                                        <p className="text-18-400 text-blue-v-dark">TERM</p>
                                        <p className="text-18-700 text-blue-v-dark mb-4">6 - 12 months</p>
                                        <p className="text-18-400 text-blue-v-dark">INTEREST</p>
                                        <p className="text-18-700 text-blue-v-dark mb-4">15%</p>
                                        <p className="text-18-400 text-blue-v-dark">CAPITAL GUARANTEED IN USD</p>
                                        <p className="text-18-700 text-blue-v-dark mb-4">Yes</p>
                                        <p className="text-18-400 text-blue-v-dark">BTC APPRECIATION</p>
                                        <p className="text-18-700 text-blue-v-dark">Capped: 25%</p>
                                    </div>
                                    <div className="py-4 text-center">
                                        <p className="hl-55 text-blue-v-dark">{percentage[1] - percentage[0]}%</p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-1">
                                <div className="relative bg-blue-widget w-full mb-4 rounded-md p-2">
                                    <div className="py-4 text-center">
                                        <p className="text-24-150-600 text-white mb-4">Bitcoin B</p>
                                        <p className="text-18-400 text-white">CURRENCY</p>
                                        <p className="text-18-700 text-white mb-4">Bitcoin</p>
                                        <p className="text-18-400 text-white">TERM</p>
                                        <p className="text-18-700 text-white mb-4">12-24 months</p>
                                        <p className="text-18-400 text-white">INTEREST</p>
                                        <p className="text-18-700 text-white mb-4">5%</p>
                                        <p className="text-18-400 text-white">CAPITAL GUARANTEED IN USD</p>
                                        <p className="text-18-700 text-white mb-4">No</p>
                                        <p className="text-18-400 text-white">BTC APPRECIATION</p>
                                        <p className="text-18-700 text-white">Unlimited</p>
                                    </div>
                                    <div className="py-4 text-center">
                                        <p className="hl-55 text-white">{100 - percentage[1]}%</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="flex justify-center mt-2 mb-2 pb-4">
                        <StyledSlider
                            defaultValue={[33.3, 66.6]}
                            renderTrack={Track}
                            renderThumb={Thumb}
                            value={percentage}
                            onAfterChange={(value) => setPercentage(value)}
                        />
                    </div>

                    <div className="pymd:mt-8 md:mb-12 text-center">
                        <p className="text-blue text-18-400 mb-3">
                            Use the percentage sliders  to split the fund allocation
                        </p>
                    </div>

                    <div className="flex justify-center md:mt-12 mb-6 py-4">
                        <button
                            className="bg-blue-sky mx-auto text-16-19 text-white py-3 px-12 rounded"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                  </form>
                </div>
            ) : (
                <div className="px-8">
                  <div className="px-12 mt-12 mb-4 text-center">
                    <p className="text-blue text-36-400 mb-6">
                        Before we continue, please tell us how you would allocate a $100,000
                        investment across the following three funds
                    </p>
                    <p className="text-blue-v-dark text-20-30">
                        Use the percentage sliders  in each of the boxes below.
                    </p>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="w-full lg:w-12/12 px-4">
                        <div className="flex flex-wrap pt-6 pb-4">

                            <div className="w-full lg:w-4/12 px-2">
                                <div className="relative flex flex-col min-w-0 break-words bg-blue-v-dark w-full mb-4 rounded-lg p-4">
                                    <div className="mb-0 px-4 py-3">
                                      <div className="flex flex-wrap items-center">
                                        <div className="relative w-full max-w-full flex-grow flex-1">
                                          <h2 className="text-24-150-600 text-yellow mb-4">
                                            Cash
                                          </h2>
                                          <p className="text-18-400 text-yellow">Investment Currency</p>
                                          <p className="text-18-700 text-yellow mb-4">USD EURO GBP</p>
                                          <p className="text-18-400 text-yellow">Term</p>
                                          <p className="text-18-700 text-yellow mb-4">12 - 24 months</p>
                                          <p className="text-18-400 text-yellow">Interest</p>
                                          <p className="text-18-700 text-yellow mb-4">15%</p>
                                          <p className="text-18-400 text-blue-widget-hide">1</p>
                                          <p className="text-18-700 text-blue-widget-hide mb-4">1</p>
                                          <p className="text-18-400 text-blue-widget-hide">1</p>
                                          <p className="text-18-700 text-blue-widget-hide mb-4">1</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="p-4 flex-auto">
                                      <div className="text-center">
                                        <p className="hl-55 text-yellow">{percentage[0]}%</p>
                                      </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-2">
                                <div className="relative flex flex-col min-w-0 break-words bg-yellow w-full mb-4 rounded-lg p-4">
                                    <div className="mb-0 px-4 py-3">
                                      <div className="flex flex-wrap items-center">
                                        <div className="relative w-full max-w-full flex-grow flex-1">
                                          <h2 className="text-24-150-600 text-blue-v-dark mb-4">
                                            Bitcoin A
                                          </h2>
                                          <p className="text-18-400 text-blue-v-dark">Investment Currency</p>
                                          <p className="text-18-700 text-blue-v-dark mb-4">Bitcoin</p>
                                          <p className="text-18-400 text-blue-v-dark">Term</p>
                                          <p className="text-18-700 text-blue-v-dark mb-4">6 - 12 months</p>
                                          <p className="text-18-400 text-blue-v-dark">Interest</p>
                                          <p className="text-18-700 text-blue-v-dark mb-4">15%</p>
                                          <p className="text-18-400 text-blue-v-dark">Capital guaranteed in USD</p>
                                          <p className="text-18-700 text-blue-v-dark mb-4">Yes</p>
                                          <p className="text-18-400 text-blue-v-dark">BTC Appreciation</p>
                                          <p className="text-18-700 text-blue-v-dark mb-4">Capped: 25%</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="p-4 flex-auto">
                                      <div className="text-center">
                                        <p className="hl-55 text-blue-v-dark">{percentage[1] - percentage[0]}%</p>
                                      </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full lg:w-4/12 px-2">
                                <div className="relative flex flex-col min-w-0 break-words bg-blue-widget w-full mb-4 rounded-lg p-4">
                                    <div className="mb-0 px-4 py-3">
                                      <div className="flex flex-wrap items-center">
                                        <div className="relative w-full max-w-full flex-grow flex-1">
                                          <h2 className="text-24-150-600 text-white mb-4">
                                            Bitcoin B
                                          </h2>
                                          <p className="text-18-400 text-white">Investment Currency</p>
                                          <p className="text-18-700 text-white mb-4">Bitcoin</p>
                                          <p className="text-18-400 text-white">Term</p>
                                          <p className="text-18-700 text-white mb-4">12 - 24 months</p>
                                          <p className="text-18-400 text-white">Interest</p>
                                          <p className="text-18-700 text-white mb-4">5%</p>
                                          <p className="text-18-400 text-white">Capital guaranteed in USD</p>
                                          <p className="text-18-700 text-white mb-4">No</p>
                                          <p className="text-18-400 text-white">BTC Appreciation</p>
                                          <p className="text-18-700 text-white mb-4">Unlimited</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="p-4 flex-auto">
                                      <div className="text-center">
                                        <p className="hl-55 text-white">{100 - percentage[1]}%</p>
                                      </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="flex justify-center mb-8">
                        <StyledSlider
                            defaultValue={[33.3, 66.6]}
                            renderTrack={Track}
                            renderThumb={Thumb}
                            value={percentage}
                            onAfterChange={(value) => setPercentage(value)}
                        />
                    </div>

                    <div className="flex justify-center mt-12 mb-6 ">
                        <button
                            className="bg-blue-sky mx-auto text-16-19 text-white py-3 px-12 rounded"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                  </form>
                </div>
            )
        }
    </>
  );
}

