import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";

import { ReactComponent as BTCGroup } from '../../assets/img/Group 71.svg';

import { numberWithCommas } from '../../functions/NumberFunctions.js';

export default function CardBTCFundDetails(props) {
  const [fund, setFund] = useState([]);

  useEffect(() => {
    if (props.fund.fund_code) {
      setFund(props.fund);
    }
  }, [props.fund]);
  return (
    <>
      { window.innerWidth < 912 ? (
        <div className="px-4 mt-12">
            <div className="relative flex w-full flex-col bg-white-7 rounded-lg p-5 min-w-0 break-words mb-6">
              <div className="w-full flex items-center">
                <div className="w-3/12">
                  <div className="relative flex flex-col text-white w-full">
                    <BTCGroup className="size-btc-group"/>
                    <p className="text-12-700 text-center text-white mt-4">{fund.fund_currency}</p>
                  </div>
                </div>
                <div className="w-9/12">
                  <div className="relative flex flex-col w-full px-6">
                    <h2 className="text-24-performance text-white">{fund.fund_name}</h2>
                    <p className="text-12-500 text-yellow mt-4">
                      {fund.fund_description_short}
                    </p>
                  </div>
                </div>
              </div>

              <p className="text-white text-14-400 py-6">{fund.fund_description}</p>
              <hr className="mb-4 bigHr"></hr>

              <p className="pb-4 text-14-400 text-white">
                  DURATION <span className="text-right text-14-600">{fund.fund_duration} months</span>
              </p>
              <p className="pb-4 text-14-400 text-white">
                  INVESTMENT <span className="text-right text-14-600">1.0 BTC / ${numberWithCommas(Math.round(fund.fund_principal))}</span>
              </p>
              <p className="pb-4 text-14-400 text-white">
                  EXPECTED PROCEEDS <span className="text-right text-14-600">{Math.round(fund.fund_return_btc * 1000) / 1000} BTC / ${numberWithCommas(Math.round(fund.fund_return))}</span>
              </p>
              <p className="pb-4 text-14-400 text-white">
                  CLOSED <span className="text-righ text-14-600t">{fund.fund_start_date}</span>
              </p>

              {props.hasDetailsButton &&
              <div className="flex justify-center">
                <Link
                  className="bg-yellow text-blue text-16-19 px-12 py-4 rounded"
                  to={"/fund-performance/" + fund.fund_code}
                >
                  Details
                </Link>
              </div>
              }

            </div>
        </div>
        ) : (
        <div className="container relative mx-auto flex flex-wrap bg-white-7 rounded-lg h-421 mt-12">
          <div className="lg:w-12/12 px-12 pt-12">
            <div className="flex flex-wrap">
                <div className="items-center">
                  <BTCGroup className="size-btc-wide" />
                  <p className="text-16-600 text-center text-white mt-4">{fund.fund_currency}</p>
                </div>
                <div className="lg:w-9/12 items-center px-12 py-6">
                  <h2 className="text-32 text-white">{fund.fund_name}</h2>
                  <p className="text-20-30 text-yellow mt-2">
                    {fund.fund_description_short}
                  </p>
                  <p className="text-20-30 text-white mt-4">
                    {fund.fund_description}
                  </p>
                </div>
            </div>

            <div className="flex flex-wrap mt-8">

              <div className="w-150 pr-4 text-center">
                <div className="bg-white rounded mb-4">
                  <h2 className="text-16-600 text-blue">{fund.fund_state}</h2>
                </div>
                <p className="text-16-150 text-white">
                    {fund.fund_start_date}
                </p>
              </div>

              <div className="w-200 px-8">
                <div className="mb-4">
                  <h2 className="text-24-700 text-white mb-1">1 BTC</h2>
                  <h2 className="text-20-600 text-white mb-4">${numberWithCommas(Math.round(fund.fund_principal))}</h2>
                </div>
                <p className="text-16-150 text-white">
                    INVESTMENT
                </p>
              </div>

              <div className="w-150 px-8">
                <div className="mb-4">
                  <h2 className="text-24-700 text-white mb-1">{(Math.round(fund.fund_interest * 100) / 100).toFixed(2)}%</h2>
                  <h2 className="text-20-600 text-blue mb-4">.</h2>
                </div>
                <p className="text-16-150 text-white">
                    ANNUAL INTEREST
                </p>
              </div>

              <div className="w-200 px-8">
                <div className="mb-4">
                  <h2 className="text-24-700 text-white mb-1">{fund.fund_duration}</h2>
                  <h2 className="text-20-600 text-white mb-4"> months</h2>
                </div>
                <p className="text-16-150 text-white">
                    DURATION
                </p>
              </div>

              <div className="w-200 px-8 text-yellow">
                <div className="mb-4">
                  <h2 className="text-24-700 mb-1">{Math.round(fund.fund_return_btc * 1000) / 1000} BTC</h2>
                  <h2 className="text-20-600 mb-4">${numberWithCommas(Math.round(fund.fund_return))}</h2>
                </div>
                <p className="text-16-150">
                    EXPECTED PROCEEDS *
                </p>
              </div>

            {props.hasDetailsButton &&
              <div className="px-8 pt-6 text-yellow">
                <Link
                  className="bg-yellow center text-blue mb-4 text-16-19-bold px-12 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  to={"/fund-performance/" + fund.fund_code}
                >
                  Details
                </Link>
              </div>
             }

            </div>
          </div>
        </div>
        )
      }
      <p className="text-16-150-change text-light-grey mt-3 px-4">* Gross projected returns calculated as 7 day moving average of BTC daily closing price (taken from binance.com)</p>
    </>
  );
}
