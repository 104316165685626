/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";

import CookiePreferences from "../Cookies/Preferences.js";
import LinkedInInsight from "../Cookies/LinkedInInsights.js";

import ReactGA from "react-ga4";
import LinkedInTag from 'react-linkedin-insight';

const GATrackingID = "G-86QYJBNVCR";
const partnerID = "1758657";


export default function CookiePopUp() {
  const [modalIsOpen, setIsOpen] = useState(true);
  const [preferencesIsOpen, setPreferencesIsOpen] = useState(false);

  const [terms, setTerms] = useState(false);
  const [certify, setCertify] = useState(false);

  const [functional, setFunctional] = useState(true);
  const [performance, setPerformance] = useState(true);
  const [marketing, setMarketing] = useState(true);

  const handleCertifyChange = (event) => {
      setCertify(event.target.value);
  }

  const handleTermsChange = (event) => {
      setTerms(event.target.value);
  }

  function acceptCookies() {
    ReactGA.initialize(GATrackingID);
    LinkedInTag.init("1758657", 'dc', false);
    setIsOpen(false);
  }

  function denyCookies() {
    setFunctional(false);
    setPerformance(false);
    setMarketing(false);
    setIsOpen(false);
  }

  function openPreferences() {
    setPreferencesIsOpen(true);
    setIsOpen(false);
  }

  const onSubmit = (event) => {
        event.preventDefault();

        if (marketing) {
            ReactGA.initialize(GATrackingID);
            LinkedInTag.init("1758657", 'dc', false);
        }

        setPreferencesIsOpen(false);
  };

  const handlePerformanceChange = (event) => {
      event.preventDefault();
      setPerformance(!performance);
  };

  const handleMarketingChange = (event) => {
      event.preventDefault();
      setMarketing(!marketing);
  };

  const location = useLocation();

  useEffect(() => {
      const currentPath = location.pathname + location.search;
      if (marketing) {
        ReactGA.send({ hitType: "pageview", page: currentPath });
        LinkedInTag.track();
      }
  }, [location]);

  return (
    <>
      { modalIsOpen ? (
        <div className="bottom-0 fixed w-full z-50 shadow">
          <div className="bg-white w-full absolute bottom-0 z-50 rounded">
              <div className="container mx-auto">
                  <div className="flex flex-wrap items-center">
                    <div className="w-12/12 md:w-6/12 lg:w-6/12 px-12 md:px-4">
                      <div className="flex items-center">
                          <div className="px-8 py-4">
                              <h4 className="text-10-700 text-blue-v-dark">
                                Manage Cookie Consent
                              </h4>
                              <p className="mt-2 text-10-400 text-blue-v-dark">
                                To provide the best experiences, we use technologies like cookies to store and/or
                                access device information. Consenting to these technologies will allow us to
                                process data such as browsing behavior or unique IDs on this site. Not
                                consenting or withdrawing consent, may adversely affect certain features and functions.
                              </p>
                              <p className="mt-4 text-10-400 text-blue-v-dark">
                                <Link
                                  className="text-10-700 text-blue-v-dark underline"
                                  to="/cookie-policy"
                                >
                                  Cookie policy
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Link
                                  className="text-10-700 text-blue-v-dark underline"
                                  to="/privacy-policy"
                                >
                                  Privacy policy
                                </Link>
                              </p>
                          </div>
                      </div>
                    </div>

                    <div className="w-12/12 md:w-6/12 lg:w-6/12 px-12 md:px-4">
                      <div className="flex flex-wraap items-center">
                        <button
                            className={`bg-yellow text-16-19 text-blue-v-dark py-2 px-6 mr-1 ml-1 mb-1 rounded ${(window.innerWidth >= 768) && ' w-180-px' }`}
                            onClick={acceptCookies}
                        >
                            Accept
                        </button>
                        <button
                            className={`bg-super-light-grey text-16-19 text-blue-v-dark py-2 px-6 mr-1 ml-1 mb-1 rounded ${(window.innerWidth >= 768) && ' w-180-px' }`}
                            onClick={denyCookies}
                        >
                            Deny
                        </button>
                        <button
                            className={`bg-super-light-grey text-16-19 text-blue-v-dark py-2 px-6 mr-1 ml-1 mb-1 rounded ${(window.innerWidth >= 768) && ' w-180-px' }`}
                            onClick={openPreferences}
                        >
                            Preferences
                        </button>

                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      ) : (
        <></>
      )
      }

      { preferencesIsOpen ? (
            <div className="bg-dark-transparent fixed w-full flex h-screen bottom-0 z-100 px-4">
                <div className="w-full h-full flex items-center">
                    <div className="mx-auto bg-white rounded-lg max-w-600-px py-10">
                        <div className="mb-6 rounded-t-lg">
                            <h2 className="text-24-150-no-change text-blue-v-dark text-center">Cookie Preferences</h2>
                        </div>

                        <form onSubmit={onSubmit}>
                            <div className="px-8">
                                <div className="mb-6">
                                    <div className="flex item-center">
                                        <label className="text-16-150-change text-blue-v-dark switch">
                                            <input
                                                className="mr-4"
                                                type="checkbox"
                                                defaultChecked={functional}
                                            />
                                            <span className="slider-always-active round"></span>
                                        </label>
                                        <span className="text-16-150 text-blue-v-dark px-4">Functional (always active)</span>
                                    </div>
                                    <p className="text-12-400-150 text-blue-v-dark">
                                        These cookies allow us to remember any preferences and choices you’ve made
                                        on our website, including your username, region and language.
                                    </p>
                                </div>

                                <div className="mb-6">
                                    <div className="flex item-center">
                                        <label className="text-16-150-change text-blue-v-dark switch">
                                            <input
                                                className="mr-4"
                                                type="checkbox"
                                                defaultChecked={performance}
                                                onChange={handlePerformanceChange}
                                            />

                                            <span className="slider round"></span>
                                        </label>
                                        <span className="text-16-150 text-blue-v-dark px-4">Performance</span>
                                    </div>
                                    <p className="text-12-400-150 text-blue-v-dark">
                                        These cookies collect information about how you use a website, for
                                        example which pages you visited and which links you clicked on.
                                        None of this information can be used to identify you. It is all
                                        aggregated and, therefore, anonymised.
                                    </p>
                                </div>

                                <div className="mb-6">
                                    <div className="flex item-center">
                                        <label className="text-16-150-change text-blue-v-dark switch">
                                            <input
                                                className="mr-4"
                                                type="checkbox"
                                                defaultChecked={marketing}
                                                onChange={handleMarketingChange}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <span className="text-16-150 text-blue-v-dark px-4">Marketing</span>
                                    </div>
                                    <p className="text-12-400-150 text-blue-v-dark">
                                        These cookies enable you to share our website's content through
                                        third-party social networks and other websites. These cookies m
                                        ay also be used for advertising purposes.
                                    </p>
                                </div>


                                <div className="flex justify-center mt-8">
                                    <button
                                        className="bg-yellow mx-auto text-16-19 text-blue-v-dark py-2 px-12 rounded"
                                        type="submit"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
          ) : (
            <></>
          )
      }

    </>
  );
}