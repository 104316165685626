import React, { useState } from "react";
import { useHistory, Link } from 'react-router-dom';

import WhiteNavbar from "../Navbars/WhiteNavbar.js";
import UserPool from "./UserPool";

import { numberWithCommas } from '../../functions/NumberFunctions.js';

import Slider from 'react-input-slider';
import PasswordChecklist from "react-password-checklist";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";


function SignUp() {
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState('');

    const [location, setLocation] = useState('');
    const [locationOther, setLocationOther] = useState("")
    const [slider, setSlider] = useState({x:10});
    const [cash, setCash] = useState("");
    const [crypto, setCrypto] = useState("");

    const [terms, setTerms] = useState(false);
    const [mailList, setMailList] = useState(false);

    const handleLocationChange = (event) => {
      setLocation(event.target.value);
    }

    const handleCashChange = (event) => {
      setCash(event.target.value);
    }

    const handleCryptoChange = (event) => {
      setCrypto(event.target.value);
    }

    const handleTermsChange = (event) => {
      setTerms(event.target.value);
    }

    const handleMailChange = (event) => {
      setMailList(event.target.value);
    }

    const onSubmit = (event) => {
        event.preventDefault();

        if (email !== '') {
            if (terms === 'on') {
                if (location !== '') {
                    if (cash !== '' || crypto !== '') {
                        UserPool.signUp(email, password, [], null, (err,data) => {

                            if (err) {
                                setErrorMessage(err.message);
                            } else {
                                if (location === 'Other') {
                                    fetch("/api/register", {
                                        method: "POST",
                                        headers: {"content_type":"application/json",},
                                        body: JSON.stringify({'cognito_id': data.userSub,
                                                             'email': email,
                                                             'country_of_domicile': locationOther,
                                                             'investment_amount_available_usd': slider.x * 10000,
                                                             'investment_currency': cash.concat("_", crypto),
                                                             'mailing_list_optin': `${mailList}`.replace('on', 'true')
                                        })
                                    });
                                } else {
                                    fetch("/api/register", {
                                        method: "POST",
                                        headers: {"content_type":"application/json",},
                                        body: JSON.stringify({'cognito_id': data.userSub,
                                                             'email': email,
                                                             'country_of_domicile': location,
                                                             'investment_amount_available_usd': slider.x * 10000,
                                                             'investment_currency': cash.concat("_", crypto),
                                                             'mailing_list_optin': `${mailList}`.replace('on', 'true')
                                        })
                                    });
                                }

                                if (data) {
                                    history.push({
                                        pathname: '/research-question',
                                        state: {sub: data.userSub}
                                    });
                                }
                            }
                        });
                    } else {
                        setErrorMessage('Please select your preferred investment currency');
                    }
                } else {
                    setErrorMessage('Please select your country of domicile');
                }
            } else {
                setErrorMessage('To create an account, please agree to the Terms & Conditions');
            }
        } else {
            setErrorMessage('Please provide an email address');
        }
    };

    return (
        <>
          <section>
            <WhiteNavbar />
          </section>

          <section>
              <div className="mx-auto max-w-600-px p-8">
                <div className="text-center">
                    <h1 className="hl-48 text-blue mb-4">Welcome to VELA</h1>
                    <p className="text-20-30 text-blue-v-dark mb-12">Join our journey by creating your account</p>
                </div>
                <form className="" onSubmit={onSubmit}>
                    <div>
                        <label className="text-16-600 text-blue-v-dark" htmlFor="email">Email address *</label>
                    </div>
                    <div className="mb-4">
                        <input
                            className="h-45-px w-full border-light-grey rounded-sm"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}>
                        </input>
                    </div>
                    <div>
                        <label className="text-16-600 text-blue-v-dark" htmlFor="password">Create a password *</label>
                    </div>
                    <div className="mb-4">
                        <input
                            className="h-45-px w-full border-light-grey rounded-sm mb-2"
                            type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}>
                        </input>

                        { password ? (
                            <PasswordChecklist
                                rules={["minLength","specialChar","number","capital"]}
                                minLength={8}
                                value={password}
                                iconSize={12}
                                validColor={'#04C300'}
                                onChange={(isValid) => {}}
                            />
                            ) : (
                            <div></div>
                            )
                        }
                    </div>

                    <div>
                        <label className="text-16-600 text-blue-v-dark" htmlFor="password">Country of domicile</label>
                    </div>
                    <div className="mb-4">
                        <div>
                            <label className="text-16-29 text-blue-v-dark">
                                <input
                                    className="mr-4"
                                    type="radio"
                                    value="UK"
                                    onChange={handleLocationChange}
                                    checked={location === 'UK'}
                                />
                                United Kingdom
                            </label>
                        </div>
                        <div>
                            <label className="text-16-29 text-blue-v-dark">
                                <input
                                    className="mr-4"
                                    type="radio"
                                    value="USA"
                                    onChange={handleLocationChange}
                                    checked={location === 'USA'}
                                />
                                United States
                            </label>
                        </div>
                        <div>
                            <label className="text-16-29 text-blue-v-dark">
                                <input
                                    className="mr-4"
                                    type="radio"
                                    value="Other"
                                    onChange={handleLocationChange}
                                    checked={location === 'Other'}
                                />
                                Other
                            </label>
                        </div>
                        <div>
                            { (location === 'Other') ?
                                (   <input
                                        className="h-45-px w-full border-light-grey rounded"
                                        type="text"
                                        value={locationOther}
                                        onChange={(event) => setLocationOther(event.target.value)}
                                    >
                                    </input>
                                ) : ( <></> )
                            }
                        </div>
                    </div>

                    <div>
                        <label className="text-16-600 text-blue-v-dark">Personal investment amount available</label>
                    </div>
                    <div className="mb-4">
                      <Slider
                        styles={{
                            track: {
                                backgroundColor: '#eeeeee',
                                height: 4,
                                width: 300,
                            },
                            active: {
                              backgroundColor: '#152065'
                            },
                            thumb: {
                              backgroundColor: '#152065'
                            },
                        }}
                        xmin={1}
                        xmax={100}
                        axis="x"
                        x={slider.x}
                        onChange={({ x }) => setSlider(slider => ({ ...slider, x }))}
                      />
                      <div className="mt-1">
                        <p className="text-20-600">${numberWithCommas(slider.x * 10000)}</p>
                      </div>
                    </div>

                    <div>
                        <label className="text-16-600 text-blue-v-dark">Investment currencies of choice</label>
                    </div>
                    <div className="mb-4">
                        <div>
                            <label className="text-16-29 text-blue-v-dark">
                                <input
                                    className="mr-4 rounded-sm"
                                    type="checkbox"
                                    value="cash"
                                    onChange={handleCashChange}
                                />
                                Cash
                            </label>
                        </div>
                        <div>
                            <label className="text-16-29 text-blue-v-dark">
                                <input
                                    className="mr-4 rounded-sm"
                                    type="checkbox"
                                    value="crypto"
                                    onChange={handleCryptoChange}
                                />
                                Crypto
                            </label>
                        </div>
                    </div>

                    <div className="mb-2">
                        <label className="text-16-150-500 text-blue-v-dark">
                            <input
                                className="mr-4 rounded-sm"
                                type="checkbox"
                                defaultChecked={terms}
                                onChange={handleTermsChange}
                            />
                            I agree to the&thinsp;
                            <Link
                                className="text-16-150-500 text-blue-link"
                                to="/terms"
                            >
                            terms and conditions
                            </Link>
                            &thinsp;and&thinsp;
                            <Link
                                className="text-16-150-500 text-blue-link"
                                to="/privacy-policy"
                            >
                            privacy policy
                            </Link>
                        </label>
                    </div>
                    <div className="mb-4">
                        <label className="text-16-150-500 text-blue-v-dark">
                            <input
                                className="mr-4 rounded-sm"
                                type="checkbox"
                                defaultChecked={mailList}
                                onChange={handleMailChange}
                            />
                            I give JUMO permission to add me to the VELA mailing list
                        </label>
                    </div>
                    {errorMessage && (
                          <div className="form-error"> {errorMessage} </div>
                     )}
                    <div className="flex justify-center mt-12 mb-6 ">
                        <button
                            className="bg-blue-sky mx-auto w-full text-16-19 text-white py-3 px-12 rounded"
                            type="submit"
                        >
                            Sign Up
                        </button>
                    </div>
                </form>
                <div className="flex">
                    <p className="text-16-150-500 text-blue-v-dark">Already have an account? &thinsp;</p>
                    <Link
                        className="text-16-150-500 text-blue-link"
                        to="/login"
                    >
                    Sign in
                    </Link>
                </div>
              </div>
          </section>
        </>
    )
}

export default SignUp;