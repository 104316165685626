/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import '../assets/styles/App.css';
import '../assets/styles/tailwind.css';
import "@fontsource/montserrat";


export default function Index() {
  window.scrollTo(0, 0);
  return (
    <>
        <div className="w-full px-4">
          <h1 className="hl-48 md:text-center mb-8">
            Terms and Conditions
          </h1>
          <h2 className="text-24-500 mt-8 mb-4">
            No offer to sell or solicitation
          </h2>
          <p className="text-16-150 mb-4">
            Nothing contained on this website is, and nor shall it be construed to be, an offer to sell or solicitation of an offer to buy any investment or constitute the giving of investment advice. further, none of the information contained on this website is a recommendation to invest.
          </p>
          <p className="text-16-150 mb-4">
            This Website And All Content And Features Available Through This Website Are Intended For Educational And Informational Purposes Only. All Content Represents The Opinions Of The Vela Group. No Information Displayed Or Contained On This Website Is Intended To Be A Substitute For Professional Investment, Financial, Tax Or Legal Advice. It Is Your Sole Responsibility To Comply With Any Legislation In Your Jurisdiction, Including Any Tax Legislation.
          </p>
          <p className="text-16-150 mb-4">
            The information contained on the pages of this Website is provided to you based on your certification that you (i) are a person in the United Kingdom that is a certified high net worth individual, self-certified sophisticated investor or an investment professional; or (ii) are a person in the United States that qualifies as an Accredited Investor. This Website is not intended for use by any other person.
          </p>
          <p className="text-16-150 mb-4">
            No financial authority, including the Financial Conduct Authority of the United Kingdom or the Securities and Exchange Commission of the United States, has approved or authorised the contents of this Website. Accordingly, this Website is only directed at persons who hold, and continue to hold for the duration of their use of this Website, the above statuses.
          </p>
          <h2 className="text-24-500 mt-8 mb-4">
            No warranty of information
          </h2>
          <p className="text-16-150 mb-4">
            No warranty is given with respect to the adequacy or completeness of any disclosures or information provided on this Website or the fairness of the terms of any data presented for information purposes herein. You may not rely on the financial assumptions or estimates that are displayed on this Website. Not all risks are disclosed on this Website and risks will vary depending on your circumstances. You should independently verify any information on this Website. The VELA Group shall have no liability to you for any decisions you take based on information displayed on this Website.
          </p>
          <p className="text-16-150 mb-4">
            You warrant that you fully understand that past performance does not indicate future results and that there can be no assurance that comparable results will be achieved.  Any past performance information is subject to, and should be reviewed in light of, the assumptions accompanying that information. Forward-looking statements may be published on this Website; however, actual results may materially vary. The VELA Group undertakes no obligation to update or review any information contained on this Website, including any forward-looking statement.
          </p>
          <p className="text-16-150 mb-4">
            Neither the VELA Group nor any of its directors, officers, employees, representatives, affiliates or agents shall have any liability whatsoever arising from any error, inaccuracy or incompleteness in any of the information, materials and communications on or through this Website. Some information on the Website is in summary form for convenience of presentation and may therefore not present comprehensive facts or data that may be relevant to you or your comprehension or interpretation of the information. All data displayed on this Website relates to investments that are closed to new funding. Under no circumstances should any information be used or considered as an offer to sell, or the invitation or solicitation of an offer to buy, any investment listed on this Website.
          </p>
          <p className="text-16-150 mb-4">
            In particular, when considering the information displayed on this Website, you should be aware that:
          </p>
          <p className="text-16-150 mb-4">
            the VELA Group is committed to responsible economic, social and governance (“ESG”) strategies. The VELA Group may therefore take risks that are not consistent with non-ESG benchmarks. Demand within certain markets or sectors that the Vela Group targets as part of its commitment to ESG may not develop as forecasted or may develop more slowly than anticipated; and
          </p>
          <p className="text-16-150 mb-4">
            foreign and/or emerging markets investments may be volatile and illiquid, subject to currency fluctuations and adverse economic or political developments (amongst other risks).
          </p>
          <p className="text-16-150 mb-4">
            The above is not a comprehensive statement of risk factors. Any investment presents certain risks and these risks will change depending on the identity and circumstances of the investor. Investors must be able to afford to lose their entire investment.
          </p>
          <h2 className="text-24-500 mt-8 mb-4">
            Website Registration
          </h2>
          <p className="text-16-150 mb-4">
            There are no investment opportunities on offer on this Website.
          </p>
          <p className="text-16-150 mb-4">
            Before being granted permission to use the full functionality of the Website, you must register on the Website and qualify as either (i) a person in the United Kingdom that is a certified high net worth individual, self-certified sophisticated investor or an investment professional; or (ii) a person in the United States that is an Accredited Investor. You must provide supporting documents or third-party verification of your status and identity, as applicable and requested by us.
          </p>
          <p className="text-16-150 mb-4">
            You represent and warrant that all information you provide to register is complete, accurate and not misleading, that you are over 18 years of age (or the age of majority in your country or state of residence) and that you are fully competent to agree to these terms and conditions. You agree that, should any changes occur that might affect your status, you shall immediately provide us with such information via email to <a href="mailto:legalnotices@jumo.world">legalnotices@jumo.world</a>.  You represent and warrant that your use of the Website complies with all applicable laws, regulations, and sanctions programs and other legal or government mandates.
          </p>
          <p className="text-16-150 mb-4">
            You are responsible for the security of your account information and its contents (including, but not limited to, your username and password). You agree and acknowledge that you are aware of phishing and other privacy infringement attacks and will carefully review all communications that appear to originate from us. The VELA Group is not responsible for any loss or damage that you may incur due to your account or any of your hardware or software being compromised. If you suspect that your account has been compromised, are uncertain about a communication that purports to come from us or otherwise suspect illegitimate activity, you must immediately contact us on +27 (0)21 344 1318.
          </p>
          <p className="text-16-150 mb-4">
            You authorise us, either directly or via third parties, to conduct any “know your customer”, anti-fraud and anti-money laundering verifications as we may require and retain any information we procure or that is provided by you as required by, and in accordance with, applicable law.
          </p>
          <p className="text-16-150 mb-4">
            By accessing and using this Website, you acknowledge and declare that you are not on any trade or economic sanctions lists, such as the United Nations Security Council Sanctions List and its equivalents, nor do you reside in any restricted territory or nation.
          </p>
          <p className="text-16-150 mb-4">
            This Website may contain links to third-party sites and materials. These sites and materials may not be controlled by us. Any third-party sites or materials are not endorsed by the VELA Group. The VELA Group is not responsible for you accessing or relying on any third-party sites and materials.
          </p>
          <p className="text-16-150 mb-4">
            You may close your account at any time. Please email <a href="mailto:legalnotices@jumo.world">legalnotices@jumo.world</a> to close your account.
          </p>
          <h2 className="text-24-500 mt-8 mb-4">
            Limitation of Liability
          </h2>
          <p className="text-16-150">
            To the extent permitted under any applicable law, the vela group will not be responsible or liable to you for any loss or damages of any kind, or for any direct, indirect, economic, exemplary, special, punitive, incidental, or consequential losses or damages, that you may incur, including (without limitation) arising out of (i) your use of the website; (ii) any non-performance, defects in technical operation or downtime of the website; (iii) any virus or security breach; or (iv) any investigations by a court or regulatory body.
          </p>
          <h2 className="text-24-500 mt-8 mb-4">
            Indemnity
          </h2>
          <p className="text-16-150 mb-4">
            By using and/or accessing the website, and by consenting to these terms and conditions, you agree to indemnify and hold harmless the vela group from and against any and all liabilities or damages that directly or indirectly arise from or are related to (i) your failure to comply with these terms and conditions; (ii) your use of the website and your activities in connection with website; and (iii) any misrepresentation made by you.
          </p>
          <h2 className="text-24-500 mt-8 mb-4">
            Updates to Terms and Conditions and Withdrawal of Website Access
          </h2>
          <p className="text-16-150 mb-4">
            These terms and conditions, and all policies on the Website, govern your use of the Website. The VELA Group may change these terms and conditions, or any part of them, at any time. Each time you sign in to or otherwise access or use this Website you are entering into a new agreement with us on the then applicable terms and conditions. Your continued use of the Website indicates your acceptance of the then prevailing terms and conditions. If you do not agree to be bound by the terms and conditions you should discontinue use of this Website.
          </p>
          <p className="text-16-150 mb-4">
            The VELA Group may withdraw, suspend, refuse to offer or refuse to make available the Website, any feature of the Website or any part of them to any person or entity at any time and for any reason. The VELA Group is not required to notify you in respect of any such action or provide you with any reasoning behind any decision.
          </p>
          <h2 className="text-24-500 mt-8 mb-4">
            Limited License, Confidentiality and Non-Disclosure
          </h2>
          <p className="text-16-150 mb-4">
            The VELA Group grants you a limited, revocable, non-exclusive, non-transferable license, subject to these terms and conditions, to access and use this Website. Any unapproved or unpermitted use of the Website is expressly prohibited. You are not permitted to copy, distribute, share, publish, transmit, modify, reverse engineer or otherwise reproduce or exploit any information provided herein, in whole or in part, in any format to any third party without the express written consent of an authorised representative of the VELA Group.  In addition, you are not permitted to alter, obscure, or remove any copyright, trademark or any other notices that are provided to you in connection with the information.
          </p>
          <h2 className="text-24-500 mt-8 mb-4">
            Miscellaneous
          </h2>
          <p className="text-16-150 mb-4">
            These terms and conditions will be governed by and construed in accordance with English law.
          </p>
          <p className="text-16-150 mb-4">
            All provisions of these terms and conditions which, by their nature are continuing, will survive termination of your agreement to these terms and conditions.
          </p>
          <p className="text-16-150 mb-4">
            The VELA Group shall not be responsible for any failures or delays resulting from any situations beyond our reasonable control.
          </p>
          <p className="text-16-150 mb-4">
            In the event that any provision of these terms and conditions is held to be void or unenforceable under applicable law, that provision will be severed and the remainder of these terms and conditions will continue in full force and effect.
          </p>
          <p className="text-16-150 mb-4">
            Any notice or communication that we provide to you will be deemed to be received 24 hours after communicated to you, whether via the Website or otherwise, and unless we receive a delivery failure notification.
          </p>
          <p className="text-16-150 mb-4">
            These terms and conditions must be read in conjunction with the remaining policies contained on this Website (including, without limitation, our privacy policy).
          </p>
          <h2 className="text-24-500 mt-8 mb-4">
            Definitions
          </h2>
          <p className="text-16-150 mb-4">
            1. <b>Accredited Investor</b> has the meaning given to that term in Rule 501 of Regulation D under the United States Securities Act of 1933, as amended.
          </p>
          <p className="text-16-150 mb-4">
            2. <b>High net worth individual</b> has the meaning given to that term in The Financial Services and Markets Act 2000 (Financial Promotion) Order 2005 of the United Kingdom.
          </p>
          <p className="text-16-150 mb-4">
            3. <b>Investment professional</b> has the meaning given to that term in The Financial Services and Markets Act 2000 (Financial Promotion) Order 2005 of the United Kingdom.
          </p>
          <p className="text-16-150 mb-4">
            4. <b>Self-certified sophisticated investor</b> has the meaning given to that term in The Financial Services and Markets Act 2000 (Financial Promotion) Order 2005 of the United Kingdom.
          </p>
          <p className="text-16-150 mb-4">
            5. <b>Vela Group “we”, “us” or “our”</b> refers to JUMO World Limited (registration number 095315) and all its affiliated companies, subsidiaries, departments, partners, service providers, platforms and contractors.
          </p>
        </div>
    </>
  );
}
