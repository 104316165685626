import React, { Component } from 'react';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../../assets/styles/App.css';
import { ReactComponent as Venn } from '../../assets/img/Venn Illus.svg';

export default class HomeCarousel extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      dots: true,
      infinite: true,
      centerPadding: 0,
      slidesToShow: 5,
      slidesToScroll: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      speed: 500,
      arrows: false,

      responsive: [{
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            arrows: true
        }
      }, {
        breakpoint: 1400,
        settings: {
            slidesToShow: 3,
            arrows: false
        }
      }]
    };
    return (
      <>
        <Slider {...settings} className="py-6">

            <div className="p-4 py-6 card-slide">
              <div className="relative flex flex-col min-w-0 break-words bg-white-card bg-white-card-100 h-340 max-w-300-px w-full mb-6 rounded-b-lg rounded-t-xl mx-auto">
                <div className="bg-blue-3 rounded-t-lg h-160-px px-6">
                  <div className="w-full lg:w-12/12 py-6">
                    <div className="flex items-center">
                      <div className="w-3/12 h-full top-0">
                        <Venn className="size-venn-illus"/>
                        <p className="mt-16"></p>
                      </div>
                      <div className="w-9/12 px-6">
                        <p className="text-20-600 text-yellow">Navy</p>
                        <p className="text-yellow text-12-500 mb-4">Growth ESG facility</p>
                        <p className="text-24-800 text-white strong">12.35%</p>
                        <p className="text-12-500 text-white">Annualised Return</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="px-8 pt-6 pb-4 text-16-150 text-blue">
                  Principal <span>$9.25m</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Duration <span>7 months</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Maturity <span>31 Dec 2019</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Currency <span>USD</span>
                </p>
              </div>
            </div>

            <div className="p-4 py-6 card-slide">
              <div className="relative flex flex-col min-w-0 break-words bg-white-card bg-white-card-100 h-340 max-w-300-px w-full mb-6 rounded-b-lg rounded-t-xl mx-auto">
                <div className="bg-blue-3 rounded-t-lg h-160-px px-6">
                  <div className="w-full lg:w-12/12 py-6">
                    <div className="flex items-center">
                      <div className="w-3/12 h-full top-0">
                        <Venn className="size-venn-illus"/>
                        <p className="mt-16"></p>
                      </div>
                      <div className="w-9/12 px-6">
                        <p className="text-20-600 text-yellow">Admiral</p>
                        <p className="text-yellow text-12-500 mb-4">Growth ESG facility</p>
                        <p className="text-24-800 text-white strong">12.35%</p>
                        <p className="text-12-500 text-white">Annualised Return</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="px-8 pt-6 pb-4 text-16-150 text-blue">
                  Principal <span>$9.00m</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Duration <span>7 months</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Maturity <span>31 Jul 2020</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Currency <span>USD</span>
                </p>
              </div>
            </div>

            <div className="p-4 py-6 card-slide">
              <div className="relative flex flex-col min-w-0 break-words bg-white-card bg-white-card-100 h-340 max-w-300-px w-full mb-6 rounded-b-lg rounded-t-xl mx-auto">
                <div className="bg-blue-3 rounded-t-lg h-160-px px-6">
                  <div className="w-full lg:w-12/12 py-6">
                    <div className="flex items-center">
                      <div className="w-3/12 h-full top-0">
                        <Venn className="size-venn-illus"/>
                        <p className="mt-16"></p>
                      </div>
                      <div className="w-9/12 px-6">
                        <p className="text-20-600 text-yellow">Sapphire</p>
                        <p className="text-yellow text-12-500 mb-4">Sustainability ESG facility</p>
                        <p className="text-24-800 text-white strong">13.50%</p>
                        <p className="text-12-500 text-white">Annualised Return</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="px-8 pt-6 pb-4 text-16-150 text-blue">
                  Principal <span>$11.42m</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Duration <span>9 months</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Maturity <span>30 Apr 2020</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Currency <span>EUR GBP USD</span>
                </p>
              </div>
            </div>

            <div className="p-4 py-6 card-slide">
              <div className="relative flex flex-col min-w-0 break-words bg-white-card bg-white-card-100 h-340 max-w-300-px w-full mb-6 rounded-b-lg rounded-t-xl mx-auto">
                <div className="bg-blue-3 rounded-t-lg h-160-px px-6">
                  <div className="w-full lg:w-12/12 py-6">
                    <div className="flex items-center">
                      <div className="w-3/12 h-full top-0">
                        <Venn className="size-venn-illus"/>
                        <p className="mt-16"></p>
                      </div>
                      <div className="w-9/12 px-6">
                        <p className="text-20-600 text-yellow">Royal</p>
                        <p className="text-yellow text-12-500 mb-4">Focused return ESG Facility</p>
                        <p className="text-24-800 text-white strong">16.00%</p>
                        <p className="text-12-500 text-white">Annualised Return</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="px-8 pt-6 pb-4 text-16-150 text-blue">
                  Principal <span>$5.23m</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Duration <span>11.3 months</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Maturity <span>15 Mar 2022</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Currency <span>EUR GBP USD</span>
                </p>
              </div>
            </div>

            <div className="p-4 py-6 card-slide">
              <div className="relative flex flex-col min-w-0 break-words bg-white-card bg-white-card-100 h-340 max-w-300-px w-full mb-6 rounded-b-lg rounded-t-xl mx-auto">
                <div className="bg-blue-3 rounded-t-lg h-160-px px-6">
                  <div className="w-full lg:w-12/12 py-6">
                    <div className="flex items-center">
                      <div className="w-3/12 h-full top-0">
                        <Venn className="size-venn-illus"/>
                        <p className="mt-16"></p>
                      </div>
                      <div className="w-9/12 px-6">
                        <p className="text-20-600 text-yellow">Steel</p>
                        <p className="text-yellow text-12-500 mb-4">Focused return ESG facility</p>
                        <p className="text-24-800 text-white strong">16.00%</p>
                        <p className="text-12-500 text-white">Annualised Return</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="px-8 pt-6 pb-4 text-16-150 text-blue">
                  Principal <span>$12.78m</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Duration <span>10.5 months</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Maturity <span>15 Mar 2022</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Currency <span>EUR GBP USD</span>
                </p>
              </div>
            </div>

            <div className="p-4 py-6 card-slide">
              <div className="relative flex flex-col min-w-0 break-words bg-white-card bg-white-card-100 h-340 max-w-300-px w-full mb-6 rounded-b-lg rounded-t-xl mx-auto">
                <div className="bg-blue-3 rounded-t-lg h-160-px px-6">
                  <div className="w-full lg:w-12/12 py-6">
                    <div className="flex items-center">
                      <div className="w-3/12 h-full top-0">
                        <Venn className="size-venn-illus"/>
                        <p className="mt-16"></p>
                      </div>
                      <div className="w-9/12 px-6">
                        <p className="text-20-600 text-yellow">Navy</p>
                        <p className="text-yellow text-12-500 mb-4">Growth ESG facility</p>
                        <p className="text-24-800 text-white strong">12.35%</p>
                        <p className="text-12-500 text-white">Annualised Return</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="px-8 pt-6 pb-4 text-16-150 text-blue">
                  Principal <span>$9.25m</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Duration <span>7 months</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Maturity <span>31 Dec 2019</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Currency <span>USD</span>
                </p>
              </div>
            </div>

            <div className="p-4 py-6 card-slide">
              <div className="relative flex flex-col min-w-0 break-words bg-white-card bg-white-card-100 h-340 max-w-300-px w-full mb-6 rounded-b-lg rounded-t-xl mx-auto">
                <div className="bg-blue-3 rounded-t-lg h-160-px px-6">
                  <div className="w-full lg:w-12/12 py-6">
                    <div className="flex items-center">
                      <div className="w-3/12 h-full top-0">
                        <Venn className="size-venn-illus"/>
                        <p className="mt-16"></p>
                      </div>
                      <div className="w-9/12 px-6">
                        <p className="text-20-600 text-yellow">Admiral</p>
                        <p className="text-yellow text-12-500 mb-4">Growth ESG facility</p>
                        <p className="text-24-800 text-white strong">12.35%</p>
                        <p className="text-12-500 text-white">Annualised Return</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="px-8 pt-6 pb-4 text-16-150 text-blue">
                  Principal <span>$9.00m</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Duration <span>7 months</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Maturity <span>31 Jul 2020</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Currency <span>USD</span>
                </p>
              </div>
            </div>

            <div className="p-4 py-6 card-slide">
              <div className="relative flex flex-col min-w-0 break-words bg-white-card bg-white-card-100 h-340 max-w-300-px w-full mb-6 rounded-b-lg rounded-t-xl mx-auto">
                <div className="bg-blue-3 rounded-t-lg h-160-px px-6">
                  <div className="w-full lg:w-12/12 py-6">
                    <div className="flex items-center">
                      <div className="w-3/12 h-full top-0">
                        <Venn className="size-venn-illus"/>
                        <p className="mt-16"></p>
                      </div>
                      <div className="w-9/12 px-6">
                        <p className="text-20-600 text-yellow">Sapphire</p>
                        <p className="text-yellow text-12-500 mb-4">Sustainability ESG facility</p>
                        <p className="text-24-800 text-white strong">13.50%</p>
                        <p className="text-12-500 text-white">Annualised Return</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="px-8 pt-6 pb-4 text-16-150 text-blue">
                  Principal <span>$11.42m</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Duration <span>9 months</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Maturity <span>30 Apr 2020</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Currency <span>EUR GBP USD</span>
                </p>
              </div>
            </div>

            <div className="p-4 py-6 card-slide">
              <div className="relative flex flex-col min-w-0 break-words bg-white-card bg-white-card-100 h-340 max-w-300-px w-full mb-6 rounded-b-lg rounded-t-xl mx-auto">
                <div className="bg-blue-3 rounded-t-lg h-160-px px-6">
                  <div className="w-full lg:w-12/12 py-6">
                    <div className="flex items-center">
                      <div className="w-3/12 h-full top-0">
                        <Venn className="size-venn-illus"/>
                        <p className="mt-16"></p>
                      </div>
                      <div className="w-9/12 px-6">
                        <p className="text-20-600 text-yellow">Royal</p>
                        <p className="text-yellow text-12-500 mb-4">Focused return ESG Facility</p>
                        <p className="text-24-800 text-white strong">16.00%</p>
                        <p className="text-12-500 text-white">Annualised Return</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="px-8 pt-6 pb-4 text-16-150 text-blue">
                  Principal <span>$5.23m</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Duration <span>11.3 months</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Maturity <span>15 Mar 2022</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Currency <span>EUR GBP USD</span>
                </p>
              </div>
            </div>

            <div className="p-4 py-6 card-slide">
              <div className="relative flex flex-col min-w-0 break-words bg-white-card bg-white-card-100 h-340 max-w-300-px w-full mb-6 rounded-b-lg rounded-t-xl mx-auto">
                <div className="bg-blue-3 rounded-t-lg h-160-px px-6">
                  <div className="w-full lg:w-12/12 py-6">
                    <div className="flex items-center">
                      <div className="w-3/12 h-full top-0">
                        <Venn className="size-venn-illus"/>
                        <p className="mt-16"></p>
                      </div>
                      <div className="w-9/12 px-6">
                        <p className="text-20-600 text-yellow">Steel</p>
                        <p className="text-yellow text-12-500 mb-4">Focused return ESG facility</p>
                        <p className="text-24-800 text-white strong">16.00%</p>
                        <p className="text-12-500 text-white">Annualised Return</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="px-8 pt-6 pb-4 text-16-150 text-blue">
                  Principal <span>$12.78m</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Duration <span>10.5 months</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Maturity <span>15 Mar 2022</span>
                </p>
                <p className="px-8 pb-4 text-16-150 text-blue">
                  Currency <span>EUR GBP USD</span>
                </p>
              </div>
            </div>

        </Slider>

      </>
    );
  }
}