/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";

import Terms from "../../legal/TermsAndConditions.js";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";


export default function PopUp() {
  const [modalIsOpen, setIsOpen] = useState(true);
  const [termsIsOpen, setTermsIsOpen] = useState(false);

  const [terms, setTerms] = useState(false);
  const [certify, setCertify] = useState(false);

  // Handle terms modal
  const openTerms = (event) => {
    event.preventDefault();
    setTermsIsOpen(true);
  }

  const closeTerms = (event) => {
    event.preventDefault();
    setTermsIsOpen(false);
  }

  // Handle accept and continue
  const handleCertifyChange = (event) => {
      setCertify(event.target.value);
  }

  const handleTermsChange = (event) => {
      setTerms(event.target.value);
  }

  const onSubmit = (event) => {
        event.preventDefault();

        if (certify == 'on' && terms == 'on') {
            setIsOpen(false);
        }

    };
  return (
    <>
      { modalIsOpen ? (
        <div className="bg-dark-transparent fixed w-full flex h-screen bottom-0 z-100 px-4">
            { termsIsOpen ? (
                    <div className="container mx-auto bg-white rounded-lg max-w-1000-px mt-24 mb-8 rounded-lg overflow-y-auto">
                        <div className="w-full flex justify-end px-4 py-4">
                            <button className="bg-yellow text-blue-v-dark mb-8 text-16-150 px-8 py-2 rounded" onClick={closeTerms}>CLOSE</button>
                        </div>
                        <Terms />

                        <div className='flex justify-center mt-4'>
                            <button className="bg-yellow text-blue-v-dark mb-8 text-16-150 px-8 py-2 rounded" onClick={closeTerms}>CLOSE</button>
                        </div>
                    </div>
                ) : (
                    <div className="w-full h-full flex items-center">
                        <div className="mx-auto bg-white rounded-lg h-400-px max-w-600-px">
                            <div className="bg-blue-v-light py-10 rounded-t-lg">
                                <h2 className="text-24-150 text-blue-v-dark text-center">Before we continue</h2>
                            </div>
                            <form onSubmit={onSubmit}>
                                <div className="py-10 px-12">
                                    <div className="mb-6">
                                        <label className="text-16-150-change text-blue-v-dark">
                                            <input
                                                className="mr-4 rounded-sm"
                                                type="checkbox"
                                                defaultChecked={certify}
                                                onChange={handleCertifyChange}
                                            />
                                            I certify that I am a person in the United Kingdom that is a sophisticated investor
                                            OR a high net worth individual OR a person in the United States that qualifies as an
                                            Accredited Investor.
                                        </label>
                                    </div>

                                    <div className="mb-6">
                                        <label className="text-16-150-change text-blue-v-dark mb-6">
                                            <input
                                                className="mr-4 rounded-sm"
                                                type="checkbox"
                                                defaultChecked={terms}
                                                onChange={handleTermsChange}
                                            />
                                            I agree to the &thinsp;
                                            <Link
                                                className="text-16-150-change text-blue-link"
                                                onClick={openTerms}
                                            >
                                            terms and conditions
                                            </Link>
                                            .
                                        </label>
                                    </div>

                                    { (terms && certify) ? (
                                        <div className="flex justify-center mt-8">
                                            <button
                                                className="mx-auto text-16-19 text-blue-v-dark py-2 px-12 rounded bg-yellow"
                                                type="submit"
                                            >
                                                Continue
                                            </button>
                                        </div>
                                        ) : (
                                        <div className="flex justify-center mt-8">
                                            <button
                                                className="mx-auto text-16-19 text-blue-v-dark py-2 px-12 rounded bg-light-grey"
                                                type="submit"
                                            >
                                                Continue
                                            </button>
                                        </div>
                                        )
                                    }

                                </div>
                            </form>
                        </div>
                    </div>
                )
            }
        </div>
      ) : (
        <></>
      )
      }
    </>
  );
}