import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js";

import '../../assets/styles/App.css';
import "@fontsource/montserrat";

export default function LifetimeChart(props) {
  const [chart, setChart] = useState({});
  const canvas = useRef(null);

  const screenSize = (window.innerWidth >= 768);

  useEffect(() => {
      const config = {
          data: {
            labels: props.labels,
            datasets: [{
                type: 'line',
                yAxisID: 'A',
                label: 'Lifetime Gross Assets Underwritten',
                backgroundColor: 'rgba(255, 230, 1, 0.7)',
                borderColor: 'rgba(255, 230, 1, 0.7)',
                data: props.assetsUnderwritten,
                fill: true,
              }, {
                type: 'line',
                yAxisID: 'B',
                label: 'Lifetime Loans',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderColor: 'rgba(255, 255, 255, 0.9)',
                data: props.lifetimeLoans,
                fill: false,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            responsive: true,
            title: {
              display: false,
              text: "Orders Chart",
            },
            legend: {
              display: true,
              position: 'top',

              labels: {
                padding: 30,
                fontColor: 'white',
                pointStyle: 'circle',
                usePointStyle: true,
                }
            },
            elements: {
              point:{
                radius: 0
              }
            },
            tooltips: {
              mode: "index",
              intersect: false,
            },
            hover: {
              mode: "nearest",
              intersect: true,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  type: 'time',
                  time: {
                    displayFormats: {
                        quarter: 'MMM YYYY'
                    }
                  },
                  scaleLabel: {
                    display: false,
                    labelString: "Month",
                  },
                  gridLines: {
                    display: false,
                    color: "rgba(0, 0, 0, 0)",
                    zeroLineColor: "rgba(0, 0, 0, 0)",
                  },
                  ticks: {
                    fontColor: 'white',
                    autoSkip: true,
                    maxTicksLimit: 10,
                  },
                },
              ],
              yAxes: [{
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  display: true,
                  scaleLabel: {
                    display: true,
                    fontColor: 'white',
                    labelString: "Lifetime Gross Assets [USD]",
                  },
                  gridLines: {
                    display: true,
                    drawBorder: false,
                    color: "rgba(255, 255, 255, 0.1)",
                    zeroLineColor: "rgba(100, 100, 100, 0.1)",
                    zeroLineWidth: 1
                  },
                  ticks: {
                    fontColor: 'white',
                    autoSkip: true,
                    maxTicksLimit: 6,
                    max: 180000000,
                    min: 0,
                    callback: function(value, index, values) {
                        return value / 1e6 + 'M';
                    }
                  },
                }, {
                  id: 'B',
                  type: 'linear',
                  position: 'right',
                  display: true,
                  scaleLabel: {
                    display: true,
                    fontColor: 'white',
                    labelString: "Lifetime Loans",
                  },
                  gridLines: {
                    display: false,
                    drawBorder: false,
                    color: "white",
                    zeroLineColor: "rgba(100, 100, 100, 0.1)",
                    zeroLineWidth: 1
                  },
                  ticks: {
                    fontColor: 'white',
                    autoSkip: true,
                    maxTicksLimit: 6,
                    max: 3000000,
                    min: 0,
                    callback: function(value, index, values) {
                        return value / 1e6 + 'M';
                    }
                  },
                }],
            },
          },
        };

      setChart(new Chart(canvas.current, config));
  }, [props.labels, props.data, props.title, props.background, props.border]);
  return (
    <>
      <div className="w-full">
        <p className={`text-yellow text-20-30 mb-2 ${screenSize && 'px-8'}`}>{props.title}</p>
        <div className="relative h-400-px">
            <canvas ref={canvas}></canvas>
        </div>
      </div>
    </>
  );
}
